import React from 'react';
import AsyncImage from '../../ui/AsyncImage/AsyncImage';

import './oneSideCard.scss';

function OneSideCard(props) {
  return (
    <div className="player-card-oneside">
      <div className="inner">
        <figure>
          <AsyncImage
            src={`${props.photo_name_card}`}
            alt={props.name}
            spacerImage="spacer-player.png"
          />
        </figure>
        <div className="player-data">
          <p>{props.name}</p>
        </div>
      </div>
    </div>
  );
}

export default OneSideCard;
