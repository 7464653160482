import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { API_BASE_URL, STATIC_SERVER_URL, BASE_HREF, API_KEY } from '../../../config/config';
import Layout from '../../Layout/Layout';
import Loader from '../../common/Loader';
import Breadcrumb from '../../common/Breadcrumb';
import RecommendedNews from '../Article/RecommendedNews';
import AnalyticsService from '../../../services/analytics';
import { v4 as uuidv4 } from 'uuid';
import StaticContentImage from './StaticContentImage';
import { useRecoilValue } from 'recoil';
import { windowWidth } from 'atoms';

import '../Article/article.scss';

function StaticContent(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [article, setArticle] = useState([]);
  const [error, setError] = useState(false);
  const { id } = useParams();
  const appWindowWidth = useRecoilValue(windowWidth);

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    fetch(`${API_BASE_URL}/static/view?id=${id}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          json[0].text = arrangeImagesSources(json[0].text);
          setIsLoaded(true);
          setArticle(json);
          setError(false);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        },
      );
  }, [id]);

  function arrangeImagesSources(text) {
    const regex = new RegExp('<img class="img-fluid" src="', 'g');
    const newText = text.replace(regex, `<img class="img-fluid" src="${STATIC_SERVER_URL}/web`);
    return newText;
  }

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!isLoaded) {
    return (
      <Loader />
    );
  }
  return (
    <Layout type="static-content" data={article} cssClassName="article-container">
      {appWindowWidth < 768 && (
        <StaticContentImage article={article} />
      )}
      <section className="main-content">
        <div className="container">
          <div className="row">
            {article.map((item) => (
              <div className="col-sm-12" key={item.id}>
                <div className="article">
                  <div className="text-left" dangerouslySetInnerHTML={{ __html: item.text }} />
                  <div className="article-footer">
                    <span className="email-share">
                      <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_HREF}/tartalom/${item.page_id}`} rel="noreferrer"><img src={`${STATIC_SERVER_URL}/web/images/draft/email_share.png`} alt="küldés e-mailben" /></a>
                    </span>
                    <span className="social-share">
                      <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${BASE_HREF}/tartalom/${item.page_id}`} rel="noreferrer"><img src={`${STATIC_SERVER_URL}/web/images/draft/facebook_share.png`} alt="megosztás" /></a>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="recommended-news-container">
        <h2 className="recommended-news-header">Kapcsolódó hírek</h2>
        <RecommendedNews />
      </section>
    </Layout>
  );
}

export default StaticContent;
