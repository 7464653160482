import { Link } from "react-router-dom";

function ResultsSquadNavigation({ squad }) {
  return (
    <div className="tab-container">
      <ul>
        <li><Link to="/merkozesek/elso-csapat" className={(squad === 'elso-csapat') ? 'active' : ''}>Első csapat</Link></li>
        <li><Link to="/merkozesek/masodik-csapat" className={(squad === 'masodik-csapat') ? 'active' : ''}>Második csapat</Link></li>
        <li><Link to="/merkozesek/noi-csapat" className={(squad === 'noi-csapat') ? 'active' : ''}>Női csapat</Link></li>
      </ul>
    </div>
  )
}

export default ResultsSquadNavigation;
