import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { STATIC_SERVER_URL } from '../../config/config';
import AnalyticsService from '../../services/analytics';

import '../../sass/components/common/page404.scss';

function Page404() {
  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
  }, []);

  return (
    <div className="error-page">
      <figure>
        <img src={`${STATIC_SERVER_URL}/web/images/design/404-illustration.png`} alt="404" />
      </figure>
      <h1>404</h1>
      <p>
        Ez bizony kapufa! Az oldal amit kerestél nem létezik, próbálj meg visszatérni a&nbsp;
        <Link to="/">főoldalra!</Link>
      </p>
    </div>
  );
}

export default Page404;
