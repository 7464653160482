import React from 'react';
import { NEWS_CATEGORIES, STATIC_SERVER_URL } from '../../../config/config';

import '../../../sass/components/featured-news.scss';

function ArticleImage(props) {
  return (
    <section id="featured-news">
      <div>
        <div className="article-image-container">
          <h2>{props.title}</h2>
          <p className="article__category">{NEWS_CATEGORIES[props.category]}</p>
          <figure id="wallpaper">
            <img
              src={`${STATIC_SERVER_URL}/web/images/news/${props.photoName}`}
              alt={props.title}
            />
          </figure>
          <div className="shadow" />
        </div>
      </div>
    </section>
  )
}

export default ArticleImage;
