import React, { Component, useEffect, useState } from 'react';
import '../../ui/VideoRecommandation/videorecommandation.scss';
import { API_BASE_URL, YT_PLAYLISTS } from '../../../config/config';
import Loader from '../../common/Loader';

function PlayListDetails(props) {
  const [activatedVideo, setActivedVideo] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(null)
  const [items, setItems] = useState([])

  useEffect(() => {
    fetch(`${API_BASE_URL}/video/list?id=${props.id}&amount=${props.amount}`)
      .then((res) => res.json())
      .then(
        (json) => {
          setIsLoaded(true)
          setError(null)
          setItems(json)
        },
        (error) => {
          setIsLoaded(true)
          setError(error)
        }
      );
  }, [props.id])

  if (error) {
    return <div>Hiba történt!</div>;
  }

  if (!isLoaded) {
    return (
      <div className="video-loader-container">
        <Loader />
      </div>
    );
  }

  return (
    <div className="video-outer-container clearfix">
      <div className="row">
        {items.map((item) => (
          <div className="col-sm-12 col-lg-6" key={item.id}>
            <div className="rec-video-container">
              <h3>{item.description}</h3>
              <div className="yt-play-btn" onClick={() => setActivedVideo(item.id)}>
                <span className="triangle" />
              </div>
              <img src={`https://i.ytimg.com/vi/${item.id}/hqdefault.jpg`} alt="" width="100%" />
              <div className="shadow" />
              <iframe
                title={`video-${item.id}`}
                className={activatedVideo === item.id ? 'show' : ''}
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${item.id}?autoplay=0&rel=0`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        ))}
        <div className="text-center col-12">
          <a
            className="btn__default-outline--big"
            href={`//youtube.com/playlist?list=${YT_PLAYLISTS[props.id]}`}
            target="_blank"
            rel="noreferrer"
          >
            Tovább
          </a>
        </div>
      </div>
    </div>
  )
}

export default PlayListDetails
