import { atom } from 'recoil';

export const appLanguage = atom({
  key: 'appLanguage',
  default: 'hu',
});

export const appFrontPageNews = atom({
  key: 'appFrontPageNews',
  default: [],
});

export const windowWidth = atom({
  key: 'windowWidth',
  default: 0,
});

export const resultsTypeState = atom({
  key: 'resultsTypeState',
  default: 'fixtures',
});
