import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import Layout from '../../Layout/Layout';
import { API_BASE_URL, API_KEY, BASE_HREF, STATIC_SERVER_URL } from '../../../config/config';
import ArticleContent from './ArticleContent';
import Breadcrumb from '../../common/Breadcrumb';
import Loader from '../../common/Loader';
import AnalyticsService from '../../../services/analytics';
import { v4 as uuidv4 } from 'uuid';
import { useSetRecoilState } from 'recoil';
import { windowWidth } from 'atoms';

import './article.scss';

function Article(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [article, setArticle] = useState(null);
  const [error, setError] = useState(false);
  const { slug, uploaded } = useParams();
  const setAppWindowWidth = useSetRecoilState(windowWidth);

  useEffect(() => {
    setAppWindowWidth(window.innerWidth);

    fetch(`${API_BASE_URL}/news/show-by-slug?slug=${slug}&uploaded=${uploaded}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then(
        (json) => {
          setArticle(json[0]);
          setIsLoaded(true);
          setError(false);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          AnalyticsService.sendPageView(window.location.pathname);
          // sendPageView(json[0].title, json[0].publish_date);
        },
        (error) => {
          setArticle(null);
          setIsLoaded(true);
          setError(true);
        },
      );
  }, [slug, uploaded]);

  /* componentWillReceiveProps(nextProps) {
      this.setState(nextProps);
      this.getItems(nextProps.match.params.slug, nextProps.match.params.uploaded);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  } */

  /*const sendPageView = (title, publishDate) => {
    console.log(window.location.pathname, `Hírek | ${title} | ${publishDate}`);
    AnalyticsService.sendPageView(window.location.pathname, `Hírek | ${title} | ${publishDate}`);
  }*/

  function redirectTo404() {
    if (error) return <Navigate to="/404" />;
  }

  if (error) {
    return (
      <div>
        {redirectTo404()}
      </div>
    );
  } if (!isLoaded) {
    return <Loader />;
  }
  return (
    <Layout type="article" data={article} cssClassName="article-container">
      <div>
        <Breadcrumb />
        <section className="main-content">
          <ArticleContent
            content={article.content}
            lead={article.lead}
            photoDocumentRoot={article.photo_document_root}
            photoName={article.photo_name}
            publishDate={article.publish_date}
            publisher={article.publisher_name}
            slug={article.slug}
            title={article.title}
            uploadDate={article.upload_date}
            category={article.category}
          />
        </section>
      </div>
    </Layout>
  );
}

export default Article;
