import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Layout from '../../Layout/Layout'
import Breadcrumb from '../../common/Breadcrumb'
import PlayListDetails from '../Videos/PlayListDetails'
import AnalyticsService from '../../../services/analytics'

function PlayList() {
  const { id } = useParams()
  const [playListId, setPlayListId] = useState(null)

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPlayListId((id === 'merkozesek') ? 'matches' : 'highlights')
  }, [id])

  if (!playListId) return <div>Betöltés...</div>

  return (
    <Layout type="slim" cssClassName="article-container">
      <Breadcrumb />

      <section className="main-content playlist-container">
        <div className="container">
          <h3 className="video-header">{(id === 'merkozesek') ? 'Mérkőzések' : 'Összefoglalók'}</h3>
          <PlayListDetails id={playListId} amount={50} />
        </div>
      </section>
    </Layout>
  )
}

export default PlayList
