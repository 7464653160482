import React, { useEffect, useState } from 'react';

import { API_BASE_URL, API_KEY } from '../../config/config';
import Editorial from './Editorial';
import Featured from './Featured';
import Loader from '../common/Loader';
import { v4 as uuidv4 } from 'uuid';

import './NewsList/newslist.scss';
import { Link } from 'react-router-dom';

function ReservesNewsList(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [itemsEditorial, setItemsEditorial] = useState([]);
  const [itemsFeatured, setItemsFeatured] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch(`${API_BASE_URL}/news/latestreserves?amount=6`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4(),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then(
        (json) => {
          setItemsEditorial(json.slice(0, 2));
          setItemsFeatured(json.slice(2, 6));
          setIsLoaded(true);
          setError(false);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        }
      );
  }, []);

  if (error) {
    return <div>Hiba történt!</div>;
  }
  if (!isLoaded) {
    return (
      <div className="container">
        <h2 className="reserves-news-header">Utánpótlás</h2>

        <div className="row">
          <div className="col-sm-12 col-lg-6 editorial-loader-container">
            <Loader />
          </div>
          <div className="col-sm-12 col-lg-6 editorial-loader-container">
            <Loader />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <h2 className="reserves-news-header">Utánpótlás</h2>

      <div className="row">
        {itemsEditorial.map((item) => (
          <Editorial
            key={item.id}
            id={item.id}
            category={item.category}
            title={item.title}
            lead={item.lead}
            slug={item.slug}
            photo_name={item.photo_name}
            upload_date={item.upload_date}
            publish_date={item.publish_date}
          />
        ))}
      </div>
      <div className="row">
        {itemsFeatured.map((item) => (
          <Featured
            key={item.id}
            id={item.id}
            category={item.category}
            title={item.title}
            lead={item.lead}
            slug={item.slug}
            photo_name={item.photo_name}
            upload_date={item.upload_date}
            publish_date={item.publish_date}
          />
        ))}
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <Link className="more" to="/hirek-listazas/akademia">
            Még több utánpótlás &raquo;
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ReservesNewsList;
