import React from 'react';

import Header from './Header/Header';
import MainContent from './MainContent/MainContent';
import Footer from './Footer/Footer';
import LazyLoad from 'react-lazy-load';
import CookieConsent from 'components/common/CookieConsent/CookieConsent';
import BottomNav from 'components/shared/BottomNav/BottomNav';

function Layout(props) {
  return (
    <div className={props.cssClassName}>
      <Header type={props.type} data={props.data} />
      <MainContent>{props.children}</MainContent>
      <LazyLoad height={750}>
        <Footer />
      </LazyLoad>
      <CookieConsent />
      <BottomNav />
    </div>
  );
}

export default Layout;
