
function CartIcon() {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="28" r="2" fill="#CD131E" />
      <circle cx="24" cy="28" r="2" fill="#CD131E" />
      <path d="m28 7h-22.18l-.82-4.2a1 1 0 0 0 -1-.8h-4v2h3.18l3.82 19.2a1 1 0 0 0 1 .8h18v-2h-17.18l-.82-4h18a1 1 0 0 0 1-.78l2-9a1 1 0 0 0 -1-1.22zm-2.8 9h-17.58l-1.4-7h20.53z" />
      <path d="m0 0h32v32h-32z" fill="none" />
    </svg>
  )
}

export default CartIcon;
