import React, { Component } from 'react';
import '../../sass/components/common/searchfield.scss';
import { Navigate } from 'react-router-dom';

export default class SearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      isVisible: false,
      redirect: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.startSearch = this.startSearch.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
  }

  componentDidMount() {
    this.setState({ isVisible: this.props.active });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ isVisible: nextProps.active });
  }

  setRedirect() {
    this.setState({
      redirect: true,
    });
  }

  handleChange(event) {
    this.setState({ searchString: event.target.value });
  }

  startSearch() {
    if (this.state.redirect) {
      return <Navigate to={`/archivum/${this.state.searchString}`} />;
    }
  }

  render() {
    return (
      <div className={(this.state.isVisible === true) ? 'global-search-container' : 'global-search-container hidden'}>
        {this.startSearch()}
        <div className="container">
          <input type="search" placeholder="ezt keresem a hírek között" onChange={this.handleChange} />
          <button className={(this.state.searchString.length >= 3) ? 'enabled' : 'disabled'} onClick={this.setRedirect}>Keresés</button>
        </div>
      </div>
    );
  }
}
