import React, { Component } from 'react';
import NavigationContent from './NavigationContent';
import NavigationIcons from './NavigationIcons';

import '../../sass/components/common/navigation.scss';

export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = { scroll: 0 };
    this.handleScroll = this.handleScroll.bind(this);
    this._isMount = false;
  }

  handleScroll() {
    this.setState((state) => ({
      scroll: window.scrollY,
    }));
  }

  componentDidMount() {
    this._isMount = true;
    const el = document.getElementsByClassName('main-navigation')[0];
    if (this._isMount) {
      this.setState({ top: el.offsetTop, height: el.offsetHeight });
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentDidUpdate() {
    /* this.state.scroll > this.state.top ?
        document.body.style.paddingTop = `${this.state.height}px` :
        document.body.style.paddingTop = 0; */
  }

  componentWillUnmount() {
    this._isMount = false;
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  render() {
    return (
      <nav
        className={
          this.state.scroll > this.state.top ? 'main-navigation fixed-nav' : 'main-navigation'
        }
      >
        <NavigationContent />
        <NavigationIcons />
      </nav>
    );
  }
}
