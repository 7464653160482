import React from 'react';
import { Link } from 'react-router-dom';

import '../../sass/components/common/headline.scss';

function Headline(props) {
  return (
    <div className="headline">
      <Link to="/">
        <h1>Budapest Honvéd Online</h1>
      </Link>
    </div>
  );
}

export default Headline;
