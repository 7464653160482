import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import Editorial from '../Editorial';
import Featured from '../Featured';
import Loader from '../../common/Loader';
import { appFrontPageNews } from '../../../atoms';

import './newslist.scss';

function NewsList(props) {
  const [itemsEditorial, setItemsEditorial] = useState([]);
  const [itemsFeatured, setItemsFeatured] = useState([]);
  const [error, setError] = useState(false);
  const newsItems = useRecoilValue(appFrontPageNews);

  useEffect(() => {
    setItemsEditorial(newsItems.filter((item) => {
      return item.is_editorial !== 11;
    }).slice(0, 2));
    setItemsFeatured(newsItems.filter((item) => {
      return item.is_editorial !== 11;
    }).slice(2, 10));
    setError(false);
  }, [newsItems]);

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!props.isLoaded) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-lg-6 editorial-loader-container">
            <Loader />
          </div>
          <div className="col-sm-12 col-lg-6 editorial-loader-container">
            <Loader />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row">
        {itemsEditorial.map((item) => (
          <Editorial
            key={item.id}
            id={item.id}
            category={item.category}
            title={item.title}
            lead={item.lead}
            slug={item.slug}
            photo_name={item.photo_name}
            upload_date={item.upload_date}
            publish_date={item.publish_date}
            coverage_id={item.coverage_id}
          />
        ))}
      </div>
      <div className="row">
        {itemsFeatured.map((item) => (
          <Featured
            key={item.id}
            id={item.id}
            category={item.category}
            title={item.title}
            lead={item.lead}
            slug={item.slug}
            photo_name={item.photo_name}
            upload_date={item.upload_date}
            publish_date={item.publish_date}
            coverage_id={item.coverage_id}
          />
        ))}
      </div>
    </div>
  );
}

export default NewsList;
