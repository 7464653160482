/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import '../../sass/components/common/hamburger.scss';
import Headline from './Headline';
import MenuHeadLine from './MenuHeadLine';
import NavigationSocial from './NavigationSocial';
import NavigationContent from './NavigationContent';
export default class Hamburger extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };
    this.handleClick = this.handleClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this._isMounted = false;
  }
  handleClick() {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
  }
  handleScroll() {
    this.setState((state) => ({
      scroll: window.scrollY,
      isToggleOn: false,
    }));
  }
  componentDidMount() {
    this._isMounted = true;
    const el = document.getElementById('hamburger-navigation');
    if (this._isMounted) {
      this.setState({ top: el.offsetTop, height: el.offsetHeight });
      window.addEventListener('scroll', this.handleScroll);
    }
  }
  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = 0);
  }
  componentWillUnmount() {
    document.body.style.paddingTop = 0;
    this._isMounted = false;
    window.removeEventListener('scroll', this.handleScroll, true);
  }
  render() {
    return (
      <nav
        id="hamburger-navigation"
        className={
          this.state.scroll > this.state.top
            ? this.state.isToggleOn
              ? 'fixed-nav active'
              : 'fixed-nav'
            : this.state.isToggleOn
            ? 'active'
            : ''
        }
      >
        <Headline />
        <a
          className={`menu-toggle ${this.state.isToggleOn ? 'active' : ''}`}
          onClick={this.handleClick}
        >
          Menü
        </a>
        <div className={`menu-sub ${this.state.isToggleOn ? 'show' : ''}`}>
          <NavigationContent />
          <NavigationSocial />
        </div>
      </nav>
    );
  }
}
