import React, { useEffect, useState } from 'react';

import Breadcrumb from '../../common/Breadcrumb'
import AnalyticsService from '../../../services/analytics'
import Loader from '../../common/Loader'
import Layout from '../../Layout/Layout'
import Staff from '../Staff/Staff'

function Management(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    AnalyticsService.sendPageView(window.location.pathname);
  }, [])

  return (
    <Layout type="slim" cssClassName="staff-container">
      <Breadcrumb />
      <section className="main-content">
        <Staff
          squadId="2"
          title="Vezetőség"
          />
      </section>
    </Layout>
  )
}

export default Management
