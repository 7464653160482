import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../sass/components/common/breadcrumb.scss';
import { API_BASE_URL, API_KEY } from '../../config/config';
import { v4 as uuidv4 } from 'uuid';

export default class Breadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.getItems(this.transformPathname());
  }

  getItems(id) {
    fetch(`${API_BASE_URL}/navigation/breadcrumb?page=${encodeURIComponent(id)}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4(),
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          const data = this.transformId(json);
          this.setState({
            isLoaded: true,
            items: data,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  transformId(data) {
    const urlArr = window.location.pathname.split('/');
    if (urlArr[1] === 'jatekos-adatlap') {
      const queryArr = document.location.search.replace('?', '').split('=');
      console.log(queryArr);
      if (queryArr[0] === 'squad') {
        queryArr[1] = parseInt(queryArr[1]) === 1 ? 'elso-csapat' : 'masodik-csapat';
        data[1].link_hu = data[1].link_hu.replace(':id', queryArr[1]);
        data[1].link_en = data[1].link_en.replace(':id', queryArr[1]);
      }
    }

    return data;
  }

  transformPathname() {
    const urlArr = window.location.pathname.split('/');
    if (urlArr.length === 3 && urlArr[1] !== 'hirek-listazas') {
      urlArr[2] = ':id';
    }
    if (urlArr.length === 3 && urlArr[1] === 'validate-code') {
      urlArr[2] = ':id';
    }
    if (urlArr.length === 4 && urlArr[1] === 'hirek') {
      urlArr[2] = ':slug';
      urlArr[3] = ':uploaded';
    }
    return urlArr.join('/');
  }

  render() {
    const { error, isLoaded, items } = this.state;

    if (error) {
      return <div>Hiba történt!</div>;
    }
    if (!isLoaded) {
      return <div>Betöltés</div>;
    }
    return (
      <div className="breadcrumb">
        <div className="container">
          {items.map((item) => (
            <div className="breadcrumb__item" key={item.id}>
              <Link to={item.link_hu}>{item.label_hu}</Link>
              <span className="separator" />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
