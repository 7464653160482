import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { NEWS_CATEGORIES } from '../../config/config';
import * as ImageService from '../../services/newsImage';
import AsyncImage from './AsyncImage/AsyncImage';

function Featured(props) {
  return (
    <div className="col-lg-3" key={props.id}>
      <Link
        to={
          props.coverage_id
            ? `/online-kozvetites/${props.coverage_id}`
            : `/hirek/${props.slug}/${moment(props.upload_date).format('YYYY-MM-DD')}`
        }
      >
        <div className="news-block-small">
          <div className="news-image">
            <AsyncImage src={ImageService.getImageFileName(props.photo_name)} alt={props.title} />
          </div>
          <div className="details">
            <h3>{props.title}</h3>
            <p>{props.lead}</p>
            <p className="extra-data">
              {props.publish_date} | {NEWS_CATEGORIES[props.category]}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Featured;
