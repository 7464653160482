import React, { Component } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Breadcrumb from '../../common/Breadcrumb';
import Layout from '../../Layout/Layout';
import RecommendedNews from '../Article/RecommendedNews';
import AnalyticsService from '../../../services/analytics';
import moment from 'moment';
import { API_BASE_URL, API_KEY, STATIC_SERVER_URL } from '../../../config/config';
import { Link, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import './archive.scss';

function Archive(props) {
  const [itemsNo, setItemsNo] = useState(0);
  const [articleList, setArticleList] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(0);
  const [searchString, setSearchString] = useState('');
  const [dateFrom, setDateFrom] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setSearchString(id);
      getItems(getQueryParams(dateFrom, dateTo, limit, offset, id), false);
      getItemsNumber(getQueryParamsNumber(dateFrom, dateTo, limit, offset, id));
    } else {
      getItems(getQueryParams(dateFrom, dateTo, limit, offset, searchString), false);
      getItemsNumber(getQueryParamsNumber(dateFrom, dateTo, limit, offset, searchString));
    }
    getSearchItems();
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    sendPageView();
  }, []);

  const getItems = (params, isAdditional) => {
    fetch(params, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(
        (json) => {
          if (isAdditional) {
            const articleConcatList = articleList.concat(json);
            setArticleList(articleConcatList);
          } else {
            setArticleList(json);
          }
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const getQueryParams = (dateFromParam, dateToParam, limitParam, offsetParam, searchStringParam) => {
    let queryParams = `${API_BASE_URL}/news/archive?datefrom=${dateFromParam}&dateto=${dateToParam}&limit=${limitParam}&offset=${offsetParam}`;
    if (searchStringParam.length >= 3) queryParams += `&search=${searchStringParam}`;

    return queryParams;
  };

  const getQueryParamsNumber = (dateFromParam, dateToParam, limitParam, offsetParam, searchStringParam) => {
    let queryParams = `${API_BASE_URL}/news/archive-number?datefrom=${dateFrom}&dateto=${dateTo}`;
    if (searchStringParam.length >= 3) queryParams += `&search=${searchStringParam}`;

    return queryParams;
  };

  const getSearchItems = () => {
    fetch(API_BASE_URL + '/news/keywords', {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then(res => res.json())
      .then(
        (json) => {
          setSearchItems(json);
        },
        (error) => {
          console.log(error);
        }
      )
  };

  const sendPageView = () => {
    AnalyticsService.sendPageView(window.location.pathname);
  };

  const getItemsNumber = (params) => {
    fetch(params, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
    .then(res => res.json())
    .then(
      (json) => {
        setItemsNo(json);
        setMaxPage(Math.ceil(parseInt(json) / 10));
      },
      (error) => {
        console.log(error);
      }
    )
  };

  const searchStringChangeHandler = (e) => {
    setSearchString(e.target.value);
  }

  const dateFromChangeHandler = (e) => {
    setDateFrom(e.target.value);
  }

  const dateToChangeHandler = (e) => {
    setDateTo(e.target.value);
  }

  const loadResults = () => {
    let newOffset = offset + 10;

    setCurrentPage(currentPage + 1);
    setOffset(newOffset);

    getItems(getQueryParams(dateFrom, dateTo, limit, newOffset, searchString), true);
};

  const handleSearchFormSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setOffset(0);
    getItems(getQueryParams(dateFrom, dateTo, limit, offset, searchString), false);
    getItemsNumber(getQueryParamsNumber(dateFrom, dateTo, limit, offset, searchString));
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  const handleSearchTags = (keyword) => {
    setCurrentPage(1);
    setOffset(0);
    setSearchString(keyword);
    getItems(getQueryParams(dateFrom, dateTo, limit, offset, keyword), false);
    getItemsNumber(getQueryParamsNumber(dateFrom, dateTo, limit, offset, keyword));
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  };

  return (
    <Layout type="slim" cssClassName="article-container">
      <div>
        <Breadcrumb/>
        <section className="main-content">
          <div className="container">
            <div className="row archive-content">
              <div className="col-12">
                <h2 className="archive-header">Archívum</h2>
              </div>
              <div className="col-sm-12 col-md-9">
                <div>
                  <p className="search-results-number">Találatok száma: {itemsNo}</p>
                </div>
                {articleList.map(item => (
                  <div key={item.id}>
                    <div className="row archive-article">
                      <div className="col-sm-12 col-md-9">
                        <h3><Link to={(item.coverage_id) ? `/online-kozvetites/${item.coverage_id}` : `/hirek/${item.slug}/${moment(item.upload_date).format('YYYY-MM-DD')}`} key={item.id}>{item.title}</Link></h3>
                        <p>{item.lead}</p>
                      </div>
                      <div className="col-sm-12 col-md-3">
                        <img src={`${STATIC_SERVER_URL}/web/images/news/${item.photo_name}`} alt={item.title}/>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="more-articles">
                  <button
                    className={currentPage === maxPage ? "btn__default hidden" : "btn__default"}
                    onClick={loadResults}
                  >
                    További találatok
                  </button>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <div className="archive-sidebar">
                  <h4>Keresés</h4>
                  <form onSubmit={handleSearchFormSubmit}>
                    <div className="form-group">
                      <label htmlFor="searchString">Kulcsszó</label>
                      <input type="text" className="form-control" id="searchString" name="searchString" value={searchString} onChange={searchStringChangeHandler} placeholder=""/>
                    </div>
                    <div className="form-group">
                      <label htmlFor="dateFrom">Kezdő dátum</label>
                      <input type="date" className="form-control" id="dateFrom" name="dateFrom" value={dateFrom} placeholder="" onChange={dateFromChangeHandler} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="dateTo">Záró dátum</label>
                      <input type="date" className="form-control" id="dateTo" name="dateTo" value={dateTo} placeholder="" onChange={dateToChangeHandler} />
                    </div>
                    <button type="submit" className="btn__default">Keresés</button>
                  </form>
                  <h4>Leggyakoribb keresések</h4>
                  {searchItems.map(item => (
                    <span className={(item.keywords === searchString) ? 'search-item active' : 'search-item'} key={item.keywords} onClick={() => handleSearchTags(item.keywords)}>
                      {item.keywords}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="recommended-news-container">
              <h2 className="recommended-news-header">Kapcsolódó hírek</h2>
              <RecommendedNews/>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Archive;
