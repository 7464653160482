import React from 'react';

function VideoPreview(props) {
  const renderVideo = (id) => {
    if (props.activatedVideo === id) {
      return <iframe title={props.title} className="show" width="100%" height="100%" src={`https://www.youtube.com/embed/${id}?autoplay=0&rel=0`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />;
    }
  };

  return (
    <div className="rec-video-container">
      <h3>{props.description}</h3>
      <div className="yt-play-btn" onClick={() => props.activateVideo(props.id)}>
        <span className="triangle" />
      </div>
      <img src={`https://i.ytimg.com/vi/${props.id}/hqdefault.jpg`} alt={props.description} />
      {renderVideo(props.id)}
      <div className="shadow" />
    </div>
  );
}

export default VideoPreview;
