import React, { useState, useEffect } from "react";
import { STATIC_SERVER_URL } from "../../../config/config";

import './asyncImage.scss';

function AsyncImage({ src, alt, spacerImage }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setLoading(false);
      setImageSrc(src);
    };
    img.onerror = () => {
      setLoading(false);
      setError(true);
    };
    img.src = src;
  }, [src]);

  if (loading) {
    return <div className="imageLoader">
      <div className="imageSpinner"></div>
      <img src={`${STATIC_SERVER_URL}/web/images/draft/${spacerImage ? spacerImage : 'spacer.png'}`} alt="" />
    </div>;
  }

  if (error) {
    return <div>Error loading image</div>;
  }

  return <img src={imageSrc} alt={alt} />;
}

export default AsyncImage;
