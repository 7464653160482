import React, { Component } from 'react';
import {Link} from "react-router-dom";

import {AUTH_BASE_URL, STATIC_SERVER_URL} from "../../../config/config";
import Breadcrumb from '../../common/Breadcrumb';
import Loader from '../../common/Loader';
import Spinner from '../../common/Spinner/Spinner';
import Layout from '../../Layout/Layout';

import './parking-reservation.scss';

export default class ParkingReservation extends Component {
  constructor(props) {
      super(props);
      this.state = {
          form: {
              first_name: '',
              last_name: '',
              email: '',
              season_ticket_no: '',
              accept_terms: false,
              accept_rules: false
          },
          first_name_error: '',
          last_name_error: '',
          email_error: '',
          season_ticket_no_error: '',
          accept_terms_error: '',
          accept_rules_error: '',
          subscription_success: null,
          submit_disabled: false,
          is_loader_active: false,
          parkingNumber: 1,
          parkingLotsNumber: 40
      };

      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
  }

    componentDidMount() {
        this.sendPageView();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        this.setState({
          isLoaded: true,
          parkingNumber: 1
        });
        /*fetch(AUTH_BASE_URL + '/get-parking-clients')
            .then(res => res.json())
            .then(
                (json) => {
                    console.log(json);
                    this.setState({
                        isLoaded: true,
                        parkingNumber: json.clients
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )*/
    }

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps);
    }

    sendPageView() {
        window.ga('set', 'page', document.location.pathname);
        window.ga('send', 'pageview');
    }

    validate() {
        let isValid = true;

        if (this.state.form.last_name.length < 3) {
            this.setState({last_name_error: 'Helytelen vezetéknév!'});
            isValid = false;
        } else {
            this.setState({last_name_error: ''});
        }

        if (this.state.form.first_name.length < 3) {
            this.setState({first_name_error: 'Helytelen keresztnév!'});
            isValid = false;
        } else {
            this.setState({first_name_error: ''});
        }

        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(this.state.form.email).toLowerCase())) {
            this.setState({email_error: 'Helytelen e-mail cím!'});
            isValid = false;
        } else {
            this.setState({email_error: ''});
        }

        /*if (this.state.form.season_ticket_no.length > 12 || this.state.form.season_ticket_no.length === 0) {
            this.setState({season_ticket_no_error: 'Helytelen bérlet azonosító!'});
            isValid = false;
        } else {
            this.setState({season_ticket_no_error: ''});
        }*/

        if (this.state.form.accept_terms !== true) {
            this.setState({accept_terms_error: 'A feltételek elfogadása kötelező!'});
            isValid = false;
        } else {
            this.setState({accept_terms_error: ''});
        }

        return isValid;
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.validate() === true) {
            this.setState({
                submit_disabled: true,
                is_loader_active: true
            });
            fetch(AUTH_BASE_URL + '/register-parking-reservation', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.form)
            })
            .then(res => res.json())
            .then(
                (json) => {
                    this.setState({
                        subscription_success: json.success,
                        submit_disabled: false,
                        is_loader_active: false,
                        form: {
                            first_name: '',
                            last_name: '',
                            email: '',
                            accept_terms: false,
                            season_ticket_no: ''
                        }
                    });
                },
                (error) => {
                    console.log(error);
                    this.setState({
                        subscription_success: false,
                        is_loader_active: false,
                        submit_disabled: false
                    });
                }
            )
        } else {
            console.log('Hibás űrlap!');
        }
    }

    handleChange(event) {
        let id = event.target.id;
        let inputValue = event.target.value;
        let isChecked = event.target.checked;
        const isCheckbox = event.target.type === 'checkbox';

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [id]: isCheckbox
                    ? isChecked
                    : inputValue
            }
        }));
    }

  render() {
    const {error, isLoaded, parkingNumber, parkingLotsNumber} = this.state;

      if (error) {
        return (<div>
          {this.redirectTo404()}
        </div>
        );
      } else if (!isLoaded) {
        return (
          <Layout type="slim" cssClassName="article-container">
            <Breadcrumb/>
            <Loader/>
          </Layout>
        );
      } else {
        return (
          <Layout type="slim" cssClassName="article-container">
            <Breadcrumb/>

            <section className="main-content register-background parking-reservation"
                    style={{backgroundImage: `url(${STATIC_SERVER_URL}/web/images/draft/stadion-naplemente.jpg)`}}>
                <div className="container register-container">
                    <div className="col-12">
                        <h2 className="h2">Bozsik Aréna parkolóbérlet igénylés 2023/24</h2>
                        {parkingNumber < parkingLotsNumber && (
                            <div>
                              <p>A 2023/24-es szezonban is van lehetőség parkolóbérlet igénylésére, immár nemcsak a bérletesek számára.</p>
                              <p><strong>Ár:</strong></p>
                                <p>A 2022/23-as szezonban bérlettel rendelkezők 22 000 Ft-os áron, az új bérleteseink illetve a jegyvásárlók 25 000 Ft-os áron juthatnak hozzá.</p>
                                <p><strong>Folyamat:</strong></p>
                                <p>Kérjük add le jelentkezésed a személyes adatok megadásával. A kedvezményes ár a tavalyi bérlet sorszámának megadásával vehető igénybe.</p>
                                <p>A helyeket az igények beérkezési sorrendjében adjuk majd ki, az igénylés nem jelent automatikus vásárlást. Mindenkit kiértesítünk e-mailben az igénylésének státuszáról, ezt a visszaigazolást kérjük majd bemutatni a parkolóbérletek átvételekor.</p>
                                <p>Az e-mailes visszajelzéssel egyidejűleg a leggyorsabbak nevei leadásra kerülnek a Bozsik Aréna jegypénztárában, a bérletek átvételének időpontjáról szintén ebben az e-mailben informálunk minden szerencsés igénylőt. A leggyorsabbak mellett várólista is kialakításra kerül, ők abban az esetben tudnak parkolóbérletet vásárolni, ha a leggyorsabbak egyike eláll vételi szándékától. A várólistát is érkezési sorrendben alakítjuk ki.</p>
                            </div>
                        )}
                        {parkingNumber >= parkingLotsNumber && (
                            <p className='text-center'>A regisztráció lezárult, a szabad helyek elfogytak.</p>
                        )}
                    </div>
                    <div className="col-12">
                        {this.state.subscription_success === true && (
                            <div className="login-success-message">
                                Sikeresen regisztráltad a foglalásodat!
                            </div>
                        )}
                        {this.state.subscription_success === false && (
                            <div className="login-error-message">
                                Hiba a regisztráció közben. Vagy regisztráltál már, vagy technikai probléma történt.
                            </div>
                        )}
                        {parkingNumber < parkingLotsNumber && (
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="last_name">Vezetéknév <span>*</span></label>
                                        <input type="text" className="form-control" id="last_name" value={this.state.form.last_name}
                                            onChange={this.handleChange}/>
                                        <div className="validation_error">{this.state.last_name_error}</div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="first_name">Keresztnév <span>*</span></label>
                                        <input type="text" className="form-control" id="first_name" value={this.state.form.first_name}
                                            onChange={this.handleChange}/>
                                        <div className="validation_error">{this.state.first_name_error}</div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email">E-mail cím <span>*</span></label>
                                        <input type="text" className="form-control" id="email" value={this.state.form.email}
                                            onChange={this.handleChange}
                                            onBlur={this.handleEmailChange}/>
                                        <div className="validation_error">{this.state.email_error}</div>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="email">2022/23 bérlet sorszáma</label>
                                        <input type="text" className="form-control" id="season_ticket_no" value={this.state.form.season_ticket_no}
                                            onChange={this.handleChange}
                                            />
                                        <div className="validation_error">{this.state.season_ticket_no_error}</div>
                                    </div>
                                </div>
                                <div className="form-group form-check">
                                    <input type="checkbox" className="form-check-input" id="accept_terms" value={this.state.form.accept_terms}
                                        onChange={this.handleChange} checked={this.state.form.accept_terms} />
                                    <label className="form-check-label" htmlFor="accept_terms">Kijelentem, hogy az <Link to="/tartalom/adatvedelem" target="_blank">adatvédelmi tájékoztatót</Link> megismertem és elfogadom, s
                                        abban szereplő adatkezelésekhez hozzájárulok.</label>
                                    <div className="validation_error">{this.state.accept_terms_error}</div>
                                </div>
                                <div className="text-center">
                                    <button disabled={this.state.submit_disabled} type="submit" className="btn btn-primary">Regisztrálok</button>
                                    {this.state.is_loader_active === true && (
                                        <Spinner/>
                                    )}
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </section>

        </Layout>
      );
      }
  }
}
