import * as React from 'react';

function MainContent(props) {
  return (
    <main>
      {props.children}
    </main>
  );
}

export default MainContent;
