/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';

import { Link, Navigate, useParams } from 'react-router-dom';
import { API_BASE_URL, API_KEY, BASE_HREF, STATIC_SERVER_URL } from '../../../config/config';
import Layout from '../../Layout/Layout';
import Loader from '../../common/Loader';
import AnalyticsService from '../../../services/analytics';
import { v4 as uuidv4 } from 'uuid';

import './photogallery.scss';

function PhotoGallery(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [prevPage, setPrevPage] = useState(false);
  const [folder, setFolder] = useState('');
  const [season, setSeason] = useState('');
  const [title, setTitle] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [photoItems, setPhotoItems] = useState([]);
  const [error, setError] = useState(false);
  const { id, photo } = useParams();

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    document.addEventListener('keydown', escFunction, false);

    fetch(`${API_BASE_URL}/photo-album/details?id=${id}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then(
        (json) => {
          setPhotoItems(json.photolist);
          setIsLoaded(true);
          setError(false);
          setFolder(json.folder);
          setSeason(json.season);
          setTitle(json.title);
          setCreatedAt(moment(json.created_at).format('YYYY-MM-DD'));
          // scrollToHighlightedElem();
        },
        (error) => {
          setPhotoItems([]);
          setIsLoaded(true);
          setError(true);
        },
      );

    return function cleanup() {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [id, photo]);

  function scrollToHighlightedElem() {
    const elem = document.getElementsByClassName('sidebar__thumbnails')[0];
    const offsetTop = document.getElementById(`photo-thumbnail-${photo}`).offsetTop - elem.offsetTop;
    elem.scrollTo({ top: offsetTop, left: 0, behavior: 'smooth' });
  }

  function escFunction(event) {
    if (event.keyCode === 27) {
      setRedirect(true);
    }
    if (event.keyCode === 39) {
      setNextPage(true);
    }
    if (event.keyCode === 37) {
      setPrevPage(true);
    }
  }

  function startRedirect() {
    if (redirect) {
      return <Navigate to={`/fotoalbum/${id}`} />;
    }
  }

  function showNextPage() {
    let nextImage = parseInt(photo) + 1;
    nextImage = (nextImage === (photoItems.length)) ? (nextImage - 1) : nextImage;
    if (nextPage) {
      setNextPage(false);
      return <Navigate to={`/galeria/${id}/${nextImage}`} />;
    }
  }

  function showPrevPage() {
    let prevImage = parseInt(photo) - 1;
    prevImage = (prevImage < 0) ? 0 : prevImage;
    if (prevPage) {
      setPrevPage(false);
      return <Navigate to="/media/fotoalbumok" />;
    }
  }

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!isLoaded) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <Layout type="no-navigation" cssClassName="image-viewer-container">
      {startRedirect()}
      {showNextPage()}
      {showPrevPage()}
      <div className="row">
        <div className="col-sm-12 col-lg-10">
          <div className="image-items-container">
            <div className={(photo === 0) ? 'pager previous hidden' : 'pager previous'}>
              <Link to={`/galeria/${id}/${photo - 1}`}>
                <img className="rotated" src={`${STATIC_SERVER_URL}/web/images/draft/next_icon.png`} alt="Előző" />
              </Link>
            </div>
            <div className="text-center image-item">
              {photoItems.map((value, index) => (
                <figure key={index} className={(index === Number(photo)) ? 'show' : 'hide'}>
                  <img src={`${STATIC_SERVER_URL}/fototar/${season}/${folder}/${value}`} alt="" />
                </figure>
              ))}
            </div>
            <div className={(photo === (photoItems.length - 1)) ? 'pager next hidden' : 'pager next'}>
              <Link to={`/galeria/${id}/${parseInt(photo) + 1}`}>
                <img src={`${STATIC_SERVER_URL}/web/images/draft/next_icon.png`} alt="Következő" />
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-2 sidebar">
          <div className="sidebar__headline">
            <h2>
              {title}
            </h2>
            <p className="date">
              {createdAt}
              <br />
              Képek az albumban:&nbsp;
              {photoItems.length}
              {' '}
              db
            </p>
            <p>
              <Link to="/media/fotoalbumok" className="close-btn">bezárás</Link>
            </p>
          </div>

          <div className="sidebar__thumbnails">
            {photoItems.map((value, index) => (
              <figure key={index} className={(index === Number(photo)) ? 'active' : ''} id={`photo-thumbnail-${index}`}>
                <Link to={`/galeria/${id}/${index}`}>
                  <img src={`${STATIC_SERVER_URL}/fototar/${season}/${folder}/${value}`} alt="" />
                </Link>
              </figure>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default PhotoGallery;
