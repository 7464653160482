export const BASE_HREF = `${window.document.location.protocol}//${window.document.location.host}`;
export const HOST_NAME = window.document.location.host;
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL;
export const STATIC_SERVER_URL = process.env.REACT_APP_STATIC_SERVER_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;

export const NEWS_CATEGORIES = {
  1: 'csapat',
  2: 'klub',
  3: 'utánpótlás',
};

export const NEWS_REVERSE_CATEGORIES = {
  palya: 1,
  klub: 2,
  'merkozes-beszamolok': 3,
  interjuk: 4,
};

export const YT_PLAYLISTS = {
  aranyoroszlanok: 'PLYc52E1-QzoEzfzomVCRzJmgYDwUtTIkT',
  'honved-tv': 'PLYc52E1-QzoHqKpku4_3dOVpLsfgQ5S8H',
  matches: 'PLYc52E1-QzoFSqVY4iwTiLVgJR745MNhN',
  highlights: 'PLYc52E1-QzoHwd94Dbc9FC-fh9ZRJSaWR',
};

export const DATE_LABELS = {
  rightNow: 'éppen most',
  minutesBefore: 'perccel ezelőtt',
  hoursBefore: 'órája',
  daysBefore: 'napja',
};

export const HOTJAR = {
  id: 522549,
  sv: 6,
};
