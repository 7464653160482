import React, { Component } from 'react';
import _ from 'lodash';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import EventItem from './EventItem';
import Comments from './Comments';
import { v4 as uuidv4 } from 'uuid';


export default class MatchEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      itemsSecondHalf: [],
      itemsFirstHalf: [],
      isLoaded: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.getItems(this.props.id);
    this.refreshItems(this.props.id);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getItems(coverageId) {
    fetch(`${API_BASE_URL}/coverageevents/${coverageId}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            isLoaded: true,
            itemsSecondHalf: _.orderBy(_.filter(json, { event_half_id: 2 }), ['event_half_id', 'event_time', 'created_at'], ['desc', 'desc', 'desc']),
            itemsFirstHalf: _.orderBy(_.filter(json, { event_half_id: 1 }), ['event_half_id', 'event_time', 'created_at'], ['desc', 'desc', 'desc']),
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  refreshItems(id) {
    this.timer = setInterval(() => {
      console.log('events refreshed');
      this.getItems(id);
    }, 50000);
  }

  render() {
    const {
      error, isLoaded, itemsFirstHalf, itemsSecondHalf,
    } = this.state;

    if (error) {
      return <div>Hiba történt!</div>;
    } if (!isLoaded) {
      return <div>Betöltés</div>;
    }
    return (
      <div className="col-lg-9">
        <div className="match-centre__events">
          <h3>Események</h3>
          {!itemsFirstHalf.length && (
            <div>
              <p>A közvetítés még nem kezdődött el.</p>
            </div>
          )}

          <div id="match-centre__eventscontainer">
            <Comments coverageId={this.props.id} halfTime="2" />
            {itemsSecondHalf.map((item) => (
              <EventItem item={item} key={item.id} />
            ))}

            <Comments coverageId={this.props.id} halfTime="1" />
            {itemsFirstHalf.map((item) => (
              <EventItem item={item} key={item.id} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
