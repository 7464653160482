import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const eventTypes = {
  1: 'goal',
  2: 'caution',
  3: 'sentoff',
  4: 'penalty',
  5: 'substitution',
  6: 'whistle',
  7: ''
};

function EventItem(props) {
  return (
    <div key={props.item.id} className={`row match-centre__event ${eventTypes[props.item.event_type_id]}`}>
      <div className="col-lg-2 col-3 time">
        {!!props.item.event_time && (
          <span>
            {props.item.event_time}
            '
          </span>
        )}
      </div>
      <div className="col-lg-10 col-9 description" dangerouslySetInnerHTML={{ __html: props.item.description }} />
    </div>
  );
}

export default EventItem;
