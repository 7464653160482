/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { API_BASE_URL, API_KEY } from '../../../config/config';
import Breadcrumb from '../../common/Breadcrumb';
import AnalyticsService from '../../../services/analytics';
import Layout from '../../Layout/Layout';
import Loader from '../../common/Loader';
import { v4 as uuidv4 } from 'uuid';

import '../Standings/standings.scss';

function StandingsReserves() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [standings, setStandings] = useState([]);
  const [error, setError] = useState(false);
  const [standingsTitle, setStandingsTitle] = useState('');
  const { id } = useParams();
  const [squad, setSquad] = useState(id);

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    fetch(`${API_BASE_URL}/ifa/standings?league=${squad}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          setError(false);
          setIsLoaded(true);
          setStandings(json[0].items);
          setStandingsTitle(`${json[0].csoport} ${json[0].evad}`);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        },
      );
  }, [squad]);

  function reloadItems(event) {
    setSquad(event.target.value);
  }

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!isLoaded) {
    return (
      <div className="container">
        <h2 className="standings-header text-center">Tabellák</h2>

        <div className="row">
          <div className="col-lg-12 next-match-container">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  return (
    <Layout type="slim" cssClassName="standings-container">
      <Breadcrumb />

      <section className="main-content">
        <div className="container">
          <h2 className="standings-header text-center">{standingsTitle}</h2>
          <div className="tab-container">
            <ul className="clearfix">
              <li><Link to="/tabella/nb1">Első csapat</Link></li>
              <li><Link to="/tabella/nb3">NB III.</Link></li>
              <li><a className="active">Utánpótlás</a></li>
            </ul>
          </div>
          <div className="form-group clearfix">
            <select className="form-control float-lg-right reserves-squad-list" value={squad} onChange={reloadItems}>
              <option value="u19">U19</option>
              <option value="u17">U17</option>
              <option value="u16">U16</option>
            </select>
          </div>
          <div className="standings-content">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th />
                  <th>Csapat</th>
                  <th className="xxl">J</th>
                  <th className="xxl">GY</th>
                  <th className="xxl">D</th>
                  <th className="xxl">V</th>
                  <th className="xxl">Lőtt</th>
                  <th className="xxl">Kapott</th>
                  <th className="xxl">Gólkülönbség</th>
                  <th>Pont</th>
                </tr>
              </thead>
              <tbody>
                {standings.map((item, index) => (
                  <tr className={(item.csapat === 'BUDAPEST HONVÉD - MFA' ? 'highlight' : '')} key={index}>
                    <td>{item.hely}</td>
                    <td />
                    <td>{item.csapat}</td>
                    <td className="xxl">{item.m}</td>
                    <td className="xxl">{item.gy}</td>
                    <td className="xxl">{item.d}</td>
                    <td className="xxl">{item.v}</td>
                    <td className="xxl">{item.lg}</td>
                    <td className="xxl">{item.kg}</td>
                    <td className="xxl">{item.gk}</td>
                    <td>{item.p}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default StandingsReserves;
