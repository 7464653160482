import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../common/Breadcrumb';
import { AUTH_BASE_URL, STATIC_SERVER_URL } from '../../../config/config';
import Spinner from '../../common/Spinner/Spinner';
import AnalyticsService from '../../../services/analytics';
import Layout from '../../Layout/Layout';

import '../../auth/register/register.scss';

export default class NewsLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        accept_terms: false,
      },
      first_name_error: '',
      last_name_error: '',
      email_error: '',
      accept_terms_error: '',
      subscription_success: null,
      submit_disabled: false,
      is_loader_active: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  componentDidMount() {
    AnalyticsService.sendPageView(window.location.pathname);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  /* componentDidUpdate() {
        this.sendPageView();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    } */

  validate() {
    let isValid = true;

    if (this.state.form.last_name.length < 3) {
      this.setState({ last_name_error: 'Helytelen vezetéknév!' });
      isValid = false;
    } else {
      this.setState({ last_name_error: '' });
    }

    if (this.state.form.first_name.length < 3) {
      this.setState({ first_name_error: 'Helytelen keresztnév!' });
      isValid = false;
    } else {
      this.setState({ first_name_error: '' });
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(this.state.form.email).toLowerCase())) {
      this.setState({ email_error: 'Helytelen e-mail cím!' });
      isValid = false;
    } else {
      this.setState({ email_error: '' });
    }

    if (this.state.form.accept_terms !== true) {
      this.setState({ accept_terms_error: 'A feltételek elfogadása kötelező!' });
      isValid = false;
    } else {
      this.setState({ accept_terms_error: '' });
    }

    return isValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.validate() === true) {
      this.setState({
        submit_disabled: true,
        is_loader_active: true,
      });
      fetch(`${AUTH_BASE_URL}/register-newsletter`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.form),
      })
        .then((res) => res.json())
        .then(
          (json) => {
            this.setState({
              subscription_success: json.success,
              submit_disabled: false,
              is_loader_active: false,
              form: {
                first_name: '',
                last_name: '',
                email: '',
                accept_terms: false,
              },
            });
          },
          (error) => {
            this.setState({
              subscription_success: false,
              is_loader_active: false,
              submit_disabled: false,
            });
          },
        );
    } else {
      console.log('Hibás űrlap!');
    }
  }

  handleChange(event) {
    const { id } = event.target;
    const inputValue = event.target.value;
    const isChecked = event.target.checked;
    const isCheckbox = event.target.type === 'checkbox';

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: isCheckbox
          ? isChecked
          : inputValue,
      },
    }));
  }

  handleEmailChange(event) {

  }

  render() {
    return (
      <Layout type="slim" cssClassName="article-container">
        <Breadcrumb />

        <section
          className="main-content register-background"
          style={{ backgroundImage: `url(${STATIC_SERVER_URL}/web/images/draft/bozsik-stadion-szekek.jpg)` }}
        >
          <div className="container register-container">
            <div className="col-12">
              <h2 className="h2">Hirlevél feliratkozás</h2>
              <p>Iratkozz fel és juss hozzá az elsők között a soron következő mérkőzéseink belépőihez vagy az éves bérletekhez!</p>
              <p>Ezen kívül exkluzív illetve zártkörű eseményeinkről is így kaphatsz leggyorsabban tájékoztatást.</p>
              <p>Ha a Shop-ban már regisztráltál, nem kell újra, a termékajánlatok mellett ezeket az információkat is megkapod.</p>
            </div>
            <div className="col-12">
              {this.state.subscription_success === true && (
              <div className="login-success-message">
                Sikeresen feliratkoztál a hírlevélre!
              </div>
              )}
              {this.state.subscription_success === false && (
              <div className="login-error-message">
                Hiba a feliratkozás közben. Vagy regisztráltál már, vagy technikai probléma történt.
              </div>
              )}
              <form onSubmit={this.handleSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="last_name">
                      Vezetéknév
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      value={this.state.form.last_name}
                      onChange={this.handleChange}
                    />
                    <div className="validation_error">{this.state.last_name_error}</div>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="first_name">
                      Keresztnév
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      value={this.state.form.first_name}
                      onChange={this.handleChange}
                    />
                    <div className="validation_error">{this.state.first_name_error}</div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="email">
                      E-mail cím
                      <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      value={this.state.form.email}
                      onChange={this.handleChange}
                      onBlur={this.handleEmailChange}
                    />
                    <div className="validation_error">{this.state.email_error}</div>
                  </div>
                </div>
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="accept_terms"
                    value={this.state.form.accept_terms}
                    onChange={this.handleChange}
                  />
                  <label className="form-check-label" htmlFor="accept_terms">
                    Kijelentem, hogy az
                    <Link to="/tartalom/adatvedelem" target="_blank">adatvédelmi tájékoztatót</Link>
                    {' '}
                    megismertem és elfogadom, s
                    abban szereplő adatkezelésekhez hozzájárulok.
                  </label>
                  <div className="validation_error">{this.state.accept_terms_error}</div>
                </div>
                <div className="text-center">
                  <button disabled={this.state.submit_disabled} type="submit" className="btn btn-primary">Feliratkozom</button>
                  {this.state.is_loader_active === true && (
                  <Spinner />
                  )}
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
