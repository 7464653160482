import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import {HOST_NAME, HOTJAR} from '../../../config/config';
import CookieConsentAlert from './CookieConsentAlert';
import CookieConsentDetails from './CookieConsentDetails';
import AnalyticsService from 'services/analytics';
import { hotjar } from 'react-hotjar';

import '../../../sass/components/common/cookie-consent.scss';
import CookieConsentDisclaimer from './CookieConsentDisclaimer';

function CookieConsent(props) {
  const cookieName = 'bhoCookies';
  const cookieLevels = ['operation', 'statistics', 'advertising'];
  const [acceptedCookie, setAcceptedCookie] = useState(null);
  const [showCookieDetails, setShowCookieDetails] = useState(false);
  const [showCookieDisclaimer, setShowCookieDisclaimer] = useState(false);
  const [cookies, setCookie] = useCookies([cookieName]);

  useEffect(() => {
    if (cookies[cookieName] && cookies[cookieName].indexOf(cookieLevels[0]) !== -1) {
      setAcceptedCookie(true);
      document.body.style.overflow = "initial";
    } else {
      setAcceptedCookie(false);
      document.body.style.overflow = "hidden";
    }
  }, [])

  const acceptCookies = (levels) => {
    setAcceptedCookie(true);
    document.body.style.overflow = "initial";
    const currentDate = new Date();
    const obj = {
      path: '/',
      expires: new Date(currentDate.setMonth(currentDate.getMonth() + 12)),
      host: HOST_NAME
    };
    const acceptedLevels = [];
    for (let i = 0; i < levels; i++) {
      acceptedLevels.push(cookieLevels[i]);
    }
    setCookie(cookieName, acceptedLevels.join('|'), obj);

    if (acceptedLevels.length >= 2) {
      AnalyticsService.initialize(process.env.REACT_APP_ANALYTICS_ID);
      hotjar.initialize(HOTJAR.id, HOTJAR.sv);
    }
  };

  const acceptAllCookies = () => {
    acceptCookies(cookieLevels.length);
  };

  const toggleCookieDetails = (e) => {
    setShowCookieDisclaimer(false);
    setShowCookieDetails(e);
  };

  const toggleCookieDisclaimer = (e) => {
    setShowCookieDetails(false);
    setShowCookieDisclaimer(e);
  };

  return (
    <>
      {acceptedCookie !== null && (
        <section className={(acceptedCookie == true) ? "cookie-consent__container hidden" : "cookie-consent__container"}>
          {!showCookieDetails && !showCookieDisclaimer && <CookieConsentAlert isShowDetails={toggleCookieDetails} isAcceptCookies={acceptAllCookies} isShowDisclaimer={toggleCookieDisclaimer} />}
          {showCookieDetails && !showCookieDisclaimer && <CookieConsentDetails isShowDetails={toggleCookieDetails} isAcceptCookies={acceptCookies} />}
          {!showCookieDetails && showCookieDisclaimer && <CookieConsentDisclaimer isShowDetails={toggleCookieDetails} />}
        </section>
      )}
    </>
  )
}

export default CookieConsent;
