import React, { Component } from 'react';
import MatchScorers from './MatchScorers';
import { API_BASE_URL, API_KEY, STATIC_SERVER_URL } from '../../../config/config';
import { v4 as uuidv4 } from 'uuid';

import '../../../sass/components/match-centre/scoreboard.scss';

export default class Scoreboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      isLoaded: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.getItems(this.props.id);
    this.refreshItems(this.props.id);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getItems(coverageId) {
    fetch(`${API_BASE_URL}/coveragebasedata/${coverageId}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            isLoaded: true,
            items: json,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  refreshItems(id) {
    this.timer = setInterval(() => {
      try {
        this.getItems(id);
      } catch(err) {
        console.log(err);
      }
    }, 50000);
  }

  render() {
    const { error, isLoaded, items } = this.state;
    const crestSource = `${STATIC_SERVER_URL}/web/images/crests/`;

    if (error) {
      return <div>Hiba történt!</div>;
    } if (!isLoaded) {
      return <div>Betöltés</div>;
    }
    items[0].home_squad_crest = crestSource + items[0].home_squad_crest;
    items[0].away_squad_crest = crestSource + items[0].away_squad_crest;
    return (
      <div>
        {items.map((item) => (
          <div key={item.id}>
            <div className="row">
              <div className="col-lg-12">
                <h2 className="text-center">
                  {item.event_name}
                  ,
                  {' '}
                  {item.event_location}
                  {' '}
                  {item.date}
                </h2>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 scorers">
                <MatchScorers squad={item.home_squad_id} textAlign="text-left" coverage={this.props.id} />
              </div>
              <div className="col-4 col-lg-2 text-center">
                <figure>
                  <img src={item.home_squad_crest} alt={item.home_squad_name} />
                </figure>
                <p className="match-centre__paragraph" data-name={item.home_squad_name} data-shortname={item.home_squad_short_name} />
              </div>
              <div className="col-4 col-lg-2 text-center">
                <p className="match-centre__paragraph--big-score-card">
                  {item.goal_home}
&nbsp;:&nbsp;
                  {item.goal_away}
                </p>
              </div>
              <div className="col-4 col-lg-2 text-center">
                <figure className="">
                  <img src={item.away_squad_crest} alt={item.away_squad_name} />
                </figure>
                <p className="match-centre__paragraph" data-name={item.away_squad_name} data-shortname={item.away_squad_short_name} />
              </div>
              <div className="col-lg-3 scorers">
                <MatchScorers squad={item.away_squad_id} textAlign="text-right" coverage={this.props.id} />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
