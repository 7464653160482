import React, { Component } from 'react';
import Standings from './Standings';
import Lineups from './Lineups';
import { API_BASE_URL } from '../../../config/config';
import MatchEvents from './MatchEvents';

export default class MatchSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="col-lg-3 no-padding">
        <div className="match-centre__sidebar">
          <div className="match-centre__sidebar-lineups">
            <Lineups id={this.props.id} />
          </div>
        </div>
      </div>
    );
  }
}
