import React, { useEffect, useRef } from 'react';
import Headline from '../../common/Headline';
import Hamburger from '../../common/Hamburger';
import Navigation from '../../common/Navigation';
import FeaturedNews from '../../ui/FeaturedNews';
import ArticleImage from '../../Pages/Article/ArticleImage';
import StaticContentImage from '../../Pages/StaticContent/StaticContentImage';
import RosterSquadImage from '../../Pages/Roster/RosterSquadImage';
import { useRecoilValue } from 'recoil';
import { windowWidth } from 'atoms';

function Header(props) {
  const appWindowWidth = useRecoilValue(windowWidth);

  if (props.type === 'super-news') {
    return (
      <header>
        <Headline />
        <Hamburger />
        <Navigation />

        <section id="featured-news">
          <FeaturedNews />
        </section>
      </header>
    );
  }

  if (props.type === 'article') {
    return (
      <header>
        <Hamburger />
        <Navigation />

        {appWindowWidth >= 768 && (
          <ArticleImage
            title={props.data.title}
            category={props.data.category}
            photoName={props.data.photo_name}
          />
        )}
      </header>
    );
  }

  if (props.type === 'static-content') {
    return (
      <header>
        <Hamburger />
        <Navigation />

        {appWindowWidth >= 768 && (
          <StaticContentImage article={props.data} />
        )}
      </header>
    );
  }

  if (props.type === 'slim') {
    return (
      <header>
        <Hamburger />
        <Navigation />
      </header>
    );
  }

  if (props.type === 'roster') {
    return (
      <header>
        <Hamburger />
        <Navigation />

        <RosterSquadImage />
      </header>
    );
  }
}

export default Header;
