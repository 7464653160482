import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { appFrontPageNews, appLanguage } from '../../../atoms';
import NewsList from '../../ui/NewsList/NewsList';
import MatchList from '../../ui/MatchList/MatchList';
import VideoRecommandation from '../../ui/VideoRecommandation/VideoRecommandation';
import ReservesNewsList from '../../ui/ReservesNewsList';
import PlayerCards from '../../ui/PlayerCards/PlayerCards';
import BannerContainer from '../../common/Banner/BannerContainer';
import AnalyticsService from '../../../services/analytics';
import Layout from '../../Layout/Layout';
import { API_BASE_URL, API_KEY, BASE_HREF } from '../../../config/config';
import { v4 as uuidv4 } from 'uuid';
import LazyLoad from 'react-lazy-load';

function Frontpage() {
  const lang = useRecoilValue(appLanguage);
  const [isLoaded, setIsLoaded] = useState(false);
  const setFrontPageNews = useSetRecoilState(appFrontPageNews);

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    getFrontPageNews();
  }, []);

  const getFrontPageNews = () => {
    fetch(`${API_BASE_URL}/news/frontpage`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then(
        (json) => {
          setIsLoaded(true);
          setFrontPageNews(json);
        },
        (error) => {
          setIsLoaded(true);
        },
      );
  }

  return (
    <Layout type="super-news" cssClassName="frontpage-container">
      <div className="frontpage">
        <Helmet>
          <title>Legfrissebb híreink | Budapest Honvéd Online</title>
          <meta name="description" content="A Budapest Honvéd Futball Club hivatalos portálja, a Kispest-szurkolók első számú információforrása." />
          <meta property="og:url" content={`${BASE_HREF}/legfrissebb`} />
          <meta property="og:title" content="Legfrissebb híreink | Budapest Honvéd Online" />
          <meta property="og:image" content={`${BASE_HREF}/img/logos/fixtures/honvedfc.png`} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content="A Budapest Honvéd Futball Club hivatalos portálja, a Kispest-szurkolók első számú információforrása." />
        </Helmet>

        <section className="main-content">
          <NewsList isLoaded={isLoaded} />
          <LazyLoad height={200}>
            <BannerContainer type="1" cssClassName="" />
          </LazyLoad>

          <LazyLoad height={650}>
            <MatchList />
          </LazyLoad>
        </section>

        <section>
          <LazyLoad height={200}>
            <BannerContainer type="2" cssClassName="banner-container__red" />
          </LazyLoad>

          <LazyLoad height={710}>
            <VideoRecommandation />
          </LazyLoad>
        </section>

        <section className="main-content">
          <LazyLoad height={766}>
            <ReservesNewsList />
          </LazyLoad>
        </section>

        <section className="main-content__darken player-list-container">
          <LazyLoad height={475}>
            <PlayerCards />
          </LazyLoad>
        </section>
      </div>
    </Layout>
  );
}

export default Frontpage;
