import React from 'react';
import { STATIC_SERVER_URL } from '../../../config/config';

function Wallpaper(props) {
  return (
    <img src={`${STATIC_SERVER_URL}/web/images/coverage/${props.id}.jpg`} alt="" />
  );
}

export default Wallpaper;
