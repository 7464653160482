import { useRecoilState } from 'recoil';
import { resultsTypeState } from 'atoms';

import './resultsTypeNavigation.scss';

function ResultsTypeNavigation() {
  const [resultType, setResultType] = useRecoilState(resultsTypeState);

  return (
    <div className="results-type-navigation-container">
      <ul>
        <li><button onClick={() => setResultType('fixtures')} className={resultType === 'fixtures' ? 'active' : ''}>Menetrend</button></li>
        <li><button onClick={() => setResultType('results')} className={resultType === 'results' ? 'active' : ''}>Eredmények</button></li>
        <li><button onClick={() => setResultType('standings')} className={resultType === 'standings' ? 'active' : ''}>Tabella</button></li>
      </ul>
    </div>
  )
}

export default ResultsTypeNavigation;
