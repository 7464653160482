import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment/moment';
import { API_BASE_URL, API_KEY, STATIC_SERVER_URL } from '../../../config/config';
import Loader from '../../common/Loader';
import { v4 as uuidv4 } from 'uuid';

import './matchlist.scss';

const MatchList = () => {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    fetch(`${API_BASE_URL}/fixtures/next?amount=4`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4(),
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          setIsLoaded(true);
          setItems(json);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Hiba történt!</div>;
  }
  if (!isLoaded) {
    return (
      <div className="container matchlist-container">
        <h2 className="matchlist-header">Mérkőzések</h2>
        <div className="row">
          <div className="col-lg-3 next-match-container">
            <Loader />
          </div>
          <div className="col-lg-3 next-match-container">
            <Loader />
          </div>
          <div className="col-lg-3 next-match-container">
            <Loader />
          </div>
          <div className="col-lg-3 next-match-container">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container matchlist-container">
      <h2 className="matchlist-header">Mérkőzések</h2>
      <div className="row">
        {!Object.entries(items).length && (
          <div className="col-12">
            <div className="empty-next-match">
              <p className="no-data">Nincs következő mérkőzés a menetrendben.</p>
            </div>
          </div>
        )}

        {!!Object.entries(items).length &&
          items.map((item) => (
            <div className="col-lg-3" key={item.id}>
              <div
                className={
                  item.home_squad_id === 1
                    ? 'match-container home-match'
                    : 'match-container away-match'
                }
              >
                <div className="type">
                  <img src={`${STATIC_SERVER_URL}/web/images/draft/${item.event_logo}`} alt="" />
                </div>
                <div className="kickoff">
                  <p>
                    {Number(moment(item.event_date).format('HH')) === 0 && (
                      <span>{moment(item.event_date).format('YYYY.MM.DD')}</span>
                    )}
                    {Number(moment(item.event_date).format('HH')) !== 0 && (
                      <span>{moment(item.event_date).format('YYYY.MM.DD HH:mm')}</span>
                    )}
                    ,
                    <br />
                    {item.event_location}
                  </p>
                </div>
                <div className="crests">
                  <img
                    src={`${STATIC_SERVER_URL}/web/images/crests/${item.home_squad_crest}`}
                    alt=""
                  />
                  <img
                    src={`${STATIC_SERVER_URL}/web/images/crests/${item.away_squad_crest}`}
                    alt=""
                  />
                </div>
                <div className="teams">
                  {item.home_squad_name}
                  <br />
                  vs
                  <br />
                  {item.away_squad_name}
                </div>
                <div>
                  <a
                    className={`buy-ticket ${item.ticket_url ? 'show' : 'hide'}`}
                    href={item.ticket_url}
                    target="_blank"
                    title="Jegyvásárlás"
                    rel="noreferrer"
                  >
                    Jegyvásárlás
                  </a>
                </div>
              </div>
            </div>
          ))}
      </div>
      {!!Object.entries(items).length && (
        <div className="row">
          <div className="col-12 text-center">
            <Link className="fixtures" to="/merkozesek/elso-csapat">
              További mérkőzések &raquo;
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default MatchList;
