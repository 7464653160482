import React from 'react';

import { STATIC_SERVER_URL } from '../../../config/config';
import AsyncImage from '../../ui/AsyncImage/AsyncImage';

function BannerContent(props) {
  return (
    <a href={props.url} target={props.target}>
      <AsyncImage
        src={`${STATIC_SERVER_URL}/web/images/banners/${props.fileName}`}
        alt={props.altText}
        spacerImage="spacer-banner.png"
      />
    </a>
  );
}

export default BannerContent;
