import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Scoreboard from './Scoreboard';
import InnerNavigation from './InnerNavigation';
import Wallpaper from './Wallpaper';
import MatchEvents from './MatchEvents';
import MatchSidebar from './MatchSidebar';
import Layout from '../../Layout/Layout';
import AnalyticsService from '../../../services/analytics';
import { v4 as uuidv4 } from 'uuid';

import '../../../sass/components/match-centre/match-centre.scss';

function MatchCentre(props) {
  const { id } = useParams();

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [id]);

  return (
    <Layout type="slim" cssClassName="article-container">
      <div>
        <header>
          <figure id="wallpaper">
            <Wallpaper id={id} />
          </figure>

          <section className="scoreboard">
            <Scoreboard id={id} />
          </section>
        </header>

        <section className="match-centre__content container">
          <div className="row">
            <MatchEvents id={id} />
            <MatchSidebar id={id} />
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default MatchCentre;
