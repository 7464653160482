import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { NEWS_CATEGORIES } from '../../config/config';
import AsyncImage from './AsyncImage/AsyncImage';
import * as ImageService from '../../services/newsImage';

function Editorial(props) {
  return (
    <div className="col-sm-12 col-lg-6">
      <Link to={(props.coverage_id) ? `/online-kozvetites/${props.coverage_id}` : `/hirek/${props.slug}/${moment(props.upload_date).format('YYYY-MM-DD')}`}>
        <div className="news-block clearfix">
          <figure>
            <AsyncImage
              src={ImageService.getImageFileName(props.photo_name)}
              alt={props.title}
            />
            <div className="line" />
          </figure>
          <div className="details">
            <h3>{props.title}</h3>
            <p>{props.lead}</p>
            <p className="extra-data">
              {props.publish_date}
              {' '}
              |
              {' '}
              {NEWS_CATEGORIES[props.category]}
            </p>
          </div>
          <div className="shadow" />
        </div>
      </Link>
    </div>
  );
}

export default Editorial;
