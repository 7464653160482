import React, { Component } from 'react';
import './spinner.scss';
import { STATIC_SERVER_URL } from '../../../config/config';

export default class Spinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginModalIsVisible: true,
    };
    this.toggleLoginModal = this.toggleLoginModal.bind(this);
  }

  toggleLoginModal() {
    this.setState({ loginModalIsVisible: !this.state.loginModalIsVisible });
  }

  render() {
    return (
      <div className="spinner">
        <img src={`${STATIC_SERVER_URL}/web/images/shop/loader.gif`} alt="" />
      </div>
    );
  }
}
