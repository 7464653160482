import React, { useEffect, useState } from "react";
import Layout from "components/Layout/Layout";
import { Link, useParams } from "react-router-dom";
import SpinnerInner from "components/shared/SpinnerInner/SpinnerInner";
import { API_BASE_URL, API_KEY, STATIC_SERVER_URL } from "config/config";
import { v4 as uuidv4 } from 'uuid';

import './report.scss';

function Report() {
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [report, setReport] = useState(null);
  const [fixture, setFixture] = useState(null);
  const [eventsData, setEvents] = useState([]);
  const [referees, setReferees] = useState([]);

  useEffect(() => {
    const getRecordById = (report, id) => {
      let record = null;
      record = report.hazaijatekos.find((item) => item.jnkod === id);
      if (record) record.side = 1;
      if (!record) {
        record = report.vendegjatekos.find((item) => item.jnkod === id);
        record.side = 2;
      }

      return record;
    }
    const mapEvents = (report) => {
      const events = [];

      // gólszerzők
      report.gollovok.forEach((event) => {
        const eventDetails = getRecordById(report, event.jnkod);
        events.push({
          side: eventDetails.side,
          type: 1,
          time: Number(event.ido),
          ownGoal: !!event.ongol,
          name: eventDetails.jnnev,
          jersey: eventDetails.mez,
        });
      });

      // lapok
      report.lapok.forEach((event) => {
        const eventDetails = getRecordById(report, event.jnkod);
        events.push({
          side: eventDetails.side,
          type: 2,
          time: Number(event.ido),
          cardType: event.lap,
          name: eventDetails.jnnev,
          jersey: eventDetails.mez,
        });
      });

      // cserék
      report.cserek.forEach((event) => {
        const eventDetails = getRecordById(report, event.jnkod);
        events.push({
          side: eventDetails.side,
          type: 3,
          time: Number(event.csereido),
          direction: (event.irany === 'ki') ? 0 : 1,
          name: eventDetails.jnnev,
          jersey: eventDetails.mez,
        });
      });

      return events;
    }

    const getReferees = (refereeList) => {
      const list = [];

      refereeList.forEach((referee, index) => {
        if (referee.jnnev !== null) {
          list.push({
            id: index,
            name: referee.jnnev,
          });
        }
      });

      return list;
    }

    const getFixtureDetails = (id, report) => {
      fetch(`${API_BASE_URL}/fixtures/show-by-ifa-id?id=${id}`, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': API_KEY,
          'X-Request-ID': uuidv4()
        }
      })
        .then((res) => res.json())
        .then(
          (json) => {
            const eventsData = mapEvents(report).sort((a, b) => a.time - b.time);
            setEvents(eventsData);
            setFixture(json[0]);
            setReport(report);
            setReferees(getReferees(report.jatekvezetok));
            setIsLoaded(true);
            setError(false);
          },
          (error) => {
            setIsLoaded(true);
            setError(true);
          },
        );
    }

    fetch(`${API_BASE_URL}/fixtures/report?id=${id}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          getFixtureDetails(id, json[0])
          setError(false);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        },
      );
  }, []);

  const sendPageView = () => {
    AnalyticsService.sendPageView(window.location.pathname);
  };

  if (!isLoaded) {
    return (
      <Layout type="slim" cssClassName="results-container">
        <section className="main-content">
          <div className="container relative spinner-container">
            <SpinnerInner />
          </div>
        </section>
      </Layout>
    );
  }

  return (
    <Layout type="slim" cssClassName="results-container">
      <section className="main-content">
        <div className="container">
          <div>
            <Link className="btn-back" to="/merkozesek/elso-csapat">vissza az eredményekhez</Link>
          </div>
          <div className="report-header">
            <img src={`${STATIC_SERVER_URL}/web/images/stadium/bzsk_k06.jpg`} alt="" />
            <div className="report-header__base-data">
              <div className="report-header__content">
                <div className="venue">
                  <p>{report.datum} {report.ido} {report.stadion} | {report.evad} {report.bajnkupa} | {report.fordulo}. forduló</p>
                  <p className="referees">Vezette: {report.jvnev} (
                    {referees.map((item) => (
                      <span key={item.id}>{item.name}</span>
                    ))}
                    )
                  </p>
                </div>
                <div className="report-header__results">
                  <div>
                    {report.hazaicsapat.csapat_nev}
                    <img src={fixture.home_squad_crest} alt="" />
                    <span className="goals">{report.vegeredmeny.hgol}</span>
                  </div>
                  <div className="separator">:</div>
                  <div>
                    <span className="goals">{report.vegeredmeny.vgol}</span>
                    <img src={fixture.away_squad_crest} alt="" />
                    {report.vendegcsapat.csapat_nev}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="report-events">
            <h3>Események</h3>
            <div className="report-events__content">
              <div>
                {eventsData.filter((item) => item.side === 1).map((event, index) => (
                  <div className="details" key={index}>
                    {event.type === 1 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-goal.png`} title="Gól" alt="Gól" />}
                    {event.type === 2 && event.cardType === 1 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-yellow-card.png`} title="Figyelmeztetés" alt="Lap" />}
                    {event.type === 2 && event.cardType === 2 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-red-card.png`} title="Kiállítás" alt="Lap" />}
                    {event.type === 3 && event.direction === 0 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-subs-out.png`} title="Csere" alt="Csere" />}
                    {event.type === 3 && event.direction === 1 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-subs-in.png`} title="Csere" alt="Csere" />}
                    <span><strong>{event.time}'</strong> {event.name}</span>
                  </div>
                ))}
              </div>
              <div>
                {eventsData.filter((item) => item.side === 2).map((event, index) => (
                  <div className="details" key={index}>
                    {event.type === 1 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-goal.png`} title="Gól" alt="Gól" />}
                    {event.type === 2 && event.cardType === 1 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-yellow-card.png`} title="Figyelmeztetés" alt="Lap" />}
                    {event.type === 2 && event.cardType === 2 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-red-card.png`} title="Kiállítás" alt="Lap" />}
                    {event.type === 3 && event.direction === 0 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-subs-out.png`} title="Csere" alt="Csere" />}
                    {event.type === 3 && event.direction === 1 && <img src={`${STATIC_SERVER_URL}/web/images/coverage/event-subs-in.png`} title="Csere" alt="Csere" />}
                    <span><strong>{event.time}'</strong> {event.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="report-lineups">
            <h3>Összeállítások</h3>
            <div className="report-lineups__content">
              <div className="home-lineup">
                <div className="squads">
                  <img src={fixture.home_squad_crest} alt="" />
                  {report.hazaicsapat.csapat_nev}
                </div>
                {report.hazaijatekos.filter(item => item.kezdcsere === 'kezd').map((item, index) => (
                  <div key={index} className="home-lineup__item">
                    <div className="jersey">{item.mez}</div>
                    <div className="player">{item.jnnev}</div>
                  </div>
                ))}
              </div>

              <div className="away-lineup">
                <div className="squads">
                  <img src={fixture.away_squad_crest} alt="" />
                  {report.vendegcsapat.csapat_nev}
                </div>
                {report.vendegjatekos.filter(item => item.kezdcsere === 'kezd').map((item, index) => (
                  <div key={index} className="away-lineup__item">
                    <div className="jersey">{item.mez}</div>
                    <div className="player">{item.jnnev}</div>
                  </div>
                ))}
              </div>
            </div>

            <h4>Kispad</h4>
            <div className="report-lineups__content">
              <div className="home-lineup">
                {report.hazaijatekos.filter(item => item.kezdcsere === 'csere').map((item, index) => (
                  <div key={index} className="home-lineup__item">
                    <div className="jersey">{item.mez}</div>
                    <div className="player">{item.jnnev}</div>
                  </div>
                ))}
              </div>

              <div className="away-lineup">
                {report.vendegjatekos.filter(item => item.kezdcsere === 'csere').map((item, index) => (
                  <div key={index} className="away-lineup__item">
                    <div className="jersey">{item.mez}</div>
                    <div className="player">{item.jnnev}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Report;
