import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { API_BASE_URL, API_KEY } from '../../../config/config';
import Loader from '../../common/Loader';
import TwoSideCard from '../../Pages/Roster/TwoSideCard';
import { v4 as uuidv4 } from 'uuid';

import '../../Pages/Roster/player.scss';
import './playerList.scss';

function PlayerCards(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [players, setPlayers] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch(`${API_BASE_URL}/players/random?id=1&amount=4`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          setIsLoaded(true);
          setPlayers(json);
          setError(false);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        },
      );
  }, []);

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!isLoaded) {
    return (
      <div className="container">
        <h2 className="player-list-header">Játékosok</h2>
        <div className="row">
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <h2 className="player-list-header">Játékosok</h2>
      <div className="player-card-container">
        {players.map((item) => (
          <TwoSideCard
            key={item.id}
            id={item.id}
            squad={item.squad_id}
            name={item.name}
            photo_name_card={item.photo_name_card}
          />
        ))}
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <Link className="roster" to="/csapatok/elso-csapat">Teljes keret &raquo;</Link>
        </div>
      </div>
    </div>
  );
}

export default PlayerCards;
