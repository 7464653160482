import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { API_BASE_URL, API_KEY, STATIC_SERVER_URL } from '../../../config/config';
import { v4 as uuidv4 } from 'uuid';

import './footer.scss';

function Footer() {
  const [sponsorCategory1, setSponsorCategory1] = useState([]);
  const [sponsorCategory2, setSponsorCategory2] = useState([]);
  const [sponsorCategory3, setSponsorCategory3] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/sponsors`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4(),
      },
    })
      .then((res) => res.json())
      .then(
        (json) => {
          setSponsorCategory1(
            json.filter((item) => {
              return item.type === 1;
            })
          );

          setSponsorCategory2(
            json.filter((item) => {
              return item.type === 2;
            })
          );

          setSponsorCategory3(
            json.filter((item) => {
              return item.type === 3;
            })
          );
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  return (
    <footer className="footer">
      <section className="footer__sponsors">
        <h2 className="headline">Támogatók és partnerek</h2>
        <div className="partners-category-1 container">
          {sponsorCategory1.map((item, index) => (
            <a
              tabIndex="0"
              href={item.url}
              target="_blank"
              title={item.alt_text}
              key={index}
              rel="noreferrer"
            >
              <img
                src={`${STATIC_SERVER_URL}/web/images/sponsors/${item.file_name}`}
                className="h60"
                alt={item.alt_text}
              />
            </a>
          ))}
        </div>

        <div className="partners-category-2 container text-center">
          {sponsorCategory2.map((item, index) => (
            <a
              tabIndex="0"
              href={item.url}
              target="_blank"
              title={item.alt_text}
              key={index}
              rel="noreferrer"
            >
              <img
                src={`${STATIC_SERVER_URL}/web/images/sponsors/${item.file_name}`}
                alt={item.alt_text}
              />
            </a>
          ))}
        </div>

        <div className="partners-category-3 container text-center">
          {sponsorCategory3.map((item, index) => (
            <a
              tabIndex="0"
              href={item.url}
              target="_blank"
              title={item.alt_text}
              key={index}
              rel="noreferrer"
            >
              <img
                src={`${STATIC_SERVER_URL}/web/images/sponsors/${item.file_name}`}
                alt={item.alt_text}
              />
            </a>
          ))}
        </div>
      </section>

      <section className="footer__disclaimer">
        <figure className="footer__crest">
          <img
            src={`${STATIC_SERVER_URL}/web/images/crests/khfc.png`}
            alt="Budapest Honvéd címer"
          />
        </figure>
        <div className="footer__social container text-center">
          <a
            tabIndex="0"
            target="_blank"
            href="https://www.facebook.com/honvedfc/"
            rel="noreferrer"
          >
            <img
              src={`${STATIC_SERVER_URL}/web/images/social/icon-facebook.png`}
              alt="Facebook oldal"
            />
          </a>
          <a
            tabIndex="0"
            target="_blank"
            href="https://www.youtube.com/user/budapesthonvedfc"
            rel="noreferrer"
          >
            <img
              src={`${STATIC_SERVER_URL}/web/images/social/icon-youtube.png`}
              alt="Youtube csatorna"
            />
          </a>
          <a
            tabIndex="0"
            target="_blank"
            href="https://www.instagram.com/bphonvedfcofficial/?hl=hu"
            rel="noreferrer"
          >
            <img
              src={`${STATIC_SERVER_URL}/web/images/social/icon-instagram.png`}
              alt="Instagram"
            />
          </a>
          <a
            tabIndex="0"
            target="_blank"
            href="https://www.linkedin.com/company/2962530"
            rel="noreferrer"
          >
            <img src={`${STATIC_SERVER_URL}/web/images/social/icon-linkedin.png`} alt="LinkedIn" />
          </a>
        </div>
        <div className="footer__mobile-app container text-center">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=hu.senso.honved"
            rel="noreferrer"
          >
            <img
              src={`${STATIC_SERVER_URL}/web/images/draft/google-play-badge.svg`}
              alt="Töltsd le a Honvéd App-ot a Google Play-ről"
            />
          </a>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/honv%C3%A9d/id1481868861"
            rel="noreferrer"
          >
            <img
              src={`${STATIC_SERVER_URL}/web/images/draft/app-store-badge.svg`}
              alt="Töltsd le a Honvéd App-ot az App Store-ból"
            />
          </a>
        </div>
        <div className="container">
          <p>
            <span className="copyright">
              Copyright &copy;
              <a
                tabIndex="0"
                href="http://www.thewebillustrated.com/"
                target="_blank"
                rel="noreferrer"
              >
                The Web Illustrated
              </a>{' '}
              2003-2024, Honvéd FC Kft.
            </span>
            <Link to="/tartalom/impresszum">Impresszum</Link>&nbsp;|&nbsp;
            <Link to="/tartalom/suti-szabalyzat">Cookie szabályzat</Link>&nbsp;|&nbsp;
            <Link to="/tartalom/adatkezelesi-szabalyzat">Adatkezelési szabályzat</Link>&nbsp;|&nbsp;
            <Link to="/tartalom/beszamolok">TAO és éves beszámolók</Link>
          </p>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
