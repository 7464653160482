import React, { Component } from 'react';
import Loader from '../../common/Loader';

import '../../../sass/components/featured-news.scss';

export default class StaticContentImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.setState({
      isLoaded: true,
      article: this.props.article,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isLoaded: true,
      article: nextProps.article,
    });
  }

  render() {
    const { error, isLoaded, article } = this.state;

    if (error) {
      return <div>Hiba történt!</div>;
    } if (!isLoaded) {
      return (
        <Loader />
      );
    }
    return (
      <section id="featured-news">
        <div>
          {article.map((item) => (
            <div className="article-image-container" key={item.id}>
              {/* <MetaTags>
                                    <title>{item.header + META_TAGS.title}</title>
                                    <meta name="description" content={item.header} />
                                    <meta property="og:title" content={item.header + META_TAGS.title} />
                                    <meta property="og:image" content={`${STATIC_SERVER_URL}/web/images/draft/${item.image}`}/>
                                </MetaTags> */}
              <h2>{item.header}</h2>
              <figure id="wallpaper">
                <img src={`${item.image}`} alt={item.header} />
              </figure>
              <div className="shadow" />
            </div>
          ))}
        </div>
      </section>
    );
  }
}
