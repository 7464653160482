function HomeIcon() {
  return (
    <svg width="24" height="24" strokeWidth="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 21H7C4.79086 21 3 19.2091 3 17V10.7076C3 9.30887 3.73061 8.01175 4.92679 7.28679L9.92679 4.25649C11.2011 3.48421 12.7989 3.48421 14.0732 4.25649L19.0732 7.28679C20.2694 8.01175 21 9.30887 21 10.7076V17C21 19.2091 19.2091 21 17 21Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 17H15" stroke="#CD131E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default HomeIcon;
