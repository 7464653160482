import { API_BASE_URL, API_KEY } from 'config/config';
import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import Cookie from '../svg/Cookie';
import { v4 as uuidv4 } from 'uuid';

function CookieConsentDisclaimer(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [article, setArticle] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/static/view?id=suti-szabalyzat`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          setIsLoaded(true);
          setArticle(json);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        },
      );
  }, [])

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    props.isShowDetails(false);
  }

  if (!isLoaded) {
    return (
      <div className='cookie-consent__disclaimer'>
        <Loader />
      </div>
    );
  }
  return (
    <div className='cookie-consent__disclaimer'>
      <div className='overflow-container'>
        {article.map((item) => (
          <p className="text-left" key={item.id} dangerouslySetInnerHTML={{ __html: item.text }} />
        ))}
        <div className="btn-container">
          <button className="btn-outline" onClick={handleBackButtonClick}>Vissza</button>
        </div>
      </div>
    </div>
  )
}

export default CookieConsentDisclaimer;
