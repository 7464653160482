import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { STATIC_SERVER_URL } from '../../../config/config';
import moment from 'moment';
import InfoIcon from 'components/shared/SVG/InfoIcon';

export default class ResultItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    this.setState({
      items: this.props.items,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      items: nextProps.items,
    });
  }

  render() {
    const { items } = this.state;

    return (
      <>
        {items.map((item) => (
          <div
            className={`result-item ${item.is_home_game ? 'home-match' : 'away-match'}`}
            key={item.id}
          >
            <div className="details">
              <div className="match-details">
                <p className="kickoff">
                  {moment(item.event_date).format('YYYY/MM/DD HH:mm')} |{' '}
                  {moment(item.event_date).format('dddd')}
                </p>
                <p className="home-squad-name">
                  <div>
                    <img
                      src={`${STATIC_SERVER_URL}/web/images/crests/${item.home_squad_crest}`}
                      alt=""
                    />
                    <span>{item.home_squad_name}</span>
                  </div>
                  <span>{item.home_squad_goals}</span>
                </p>
                <p className="away-squad-name">
                  <div>
                    <img
                      src={`${STATIC_SERVER_URL}/web/images/crests/${item.away_squad_crest}`}
                      alt=""
                    />
                    {item.away_squad_name}
                  </div>
                  <span>{item.away_squad_goals}</span>
                </p>
                <p className="venue">{item.event_location}</p>
              </div>
              <div className="match-league-info">
                <p>
                  <strong>{item.event_name}</strong>
                </p>
                {!!item.game_day && (
                  <div>
                    <h3>{item.game_day}.</h3>
                    <p>forduló</p>
                  </div>
                )}
              </div>
            </div>

            <div className="match-actions">
              {!!item.report_ifa_id && item.report_data && (
                <Link to={`/jegyzokonyv/${item.report_ifa_id}`} className="btn btn-outline">
                  Mérkőzés jegyzőkönyv
                </Link>
              )}
              {item.ticket_url && moment().unix() < moment(item.event_date).unix() && (
                <a href={item.ticket_url} target="_blank" rel="noopener noreferrer" className="btn">
                  Jegyvásárlás
                </a>
              )}
              {(item.home_squad_id === 30 || item.home_squad_id === 1) &&
                (this.props.squad === 20 || this.props.squad === 19) && (
                  <p className="free-entry">
                    <InfoIcon />
                    <span>A belépés ingyenes</span>
                  </p>
                )}
              <div>
                {item.photo_album_id && (
                  <Link
                    to={`/galeria/${item.photo_album_id}/0`}
                    title="Mérkőzés fotók"
                    className="btn"
                  >
                    Fotók
                  </Link>
                )}
                {item.video_youtube_id && (
                  <a
                    href={`https://www.youtube.com/watch?v=${item.video_youtube_id}`}
                    target="_blank"
                    title="Összefoglaló az MLSZ TV-n"
                    rel="noopener noreferrer"
                    className="btn"
                  >
                    Összefoglaló
                  </a>
                )}
              </div>
            </div>
            {/*<div className="type">
              <img
                src={`${STATIC_SERVER_URL}/web/images/draft/${item.event_logo}`}
                alt={item.home_squad_name}
              />
            </div>
            <div className="game-day">{item.game_day ? `${item.game_day}. forduló` : ''}</div>
            <div className="flex flex-col align-center match-details">
              <div className="match-date text-center">
                {moment(item.event_date).format('YYYY/MM/DD HH:mm')}
              </div>
              <div className="flex justify-center align-center">
                <div className="flex flex-row align-center home-team justify-end">
                  <span>{item.home_squad_name}</span>
                  <img
                    src={`${STATIC_SERVER_URL}/web/images/crests/${item.home_squad_crest}`}
                    alt=""
                  />
                </div>
                {item.home_squad_goals !== null && (
                  <div className="text-center score">
                    <span className={item.home_squad_goals <= item.away_squad_goals ? 'gray' : ''}>
                      {item.home_squad_goals}
                    </span>
                    <span className={item.home_squad_goals >= item.away_squad_goals ? 'gray' : ''}>
                      {item.away_squad_goals}
                    </span>
                  </div>
                )}
                {item.home_squad_goals === null && <div className="versus text-center">VS</div>}
                <div className="flex flex-row align-center away-team">
                  <img
                    src={`${STATIC_SERVER_URL}/web/images/crests/${item.away_squad_crest}`}
                    alt={item.away_squad_name}
                  />
                  <span>{item.away_squad_name}</span>
                </div>
              </div>
              <div className="venue text-center">{item.event_location}</div>
            </div>
            <div className="icon">
              {item.photo_album_id && (
                <Link to={`/fotoalbum/${item.photo_album_id}`} title="Mérkőzés fotók">
                  <img
                    src={`${STATIC_SERVER_URL}/web/images/design/icon-picture.png`}
                    alt="Mérkőzés fotók"
                  />
                </Link>
              )}
              {item.video_youtube_id && (
                <a
                  href={`https://www.youtube.com/watch?v=${item.video_youtube_id}`}
                  target="_blank"
                  title="Összefoglaló az MLSZ TV-n"
                >
                  <img
                    src={`${STATIC_SERVER_URL}/web/images/design/icon-video.png`}
                    alt="Összefoglaló az MLSZ TV-n"
                  />
                </a>
              )}
              </div>*/}
          </div>
        ))}
      </>
    );
  }
}
