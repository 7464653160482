import React from 'react';
import {Link} from 'react-router-dom';
import Cookie from '../svg/Cookie';

function CookieConsentAlert(props) {
  const showCookieDetails = () => {
    props.isShowDetails(true);
  }

  const acceptCookie = () => {
    props.isAcceptCookies();
  }

  const showCookieDisclaimer = (e) => {
    e.preventDefault();
    props.isShowDisclaimer(true);
  }

  return (
    <div className='cookie-consent__alert'>
      <Cookie cssClassName='text-center' />
      <h3>Süti beállítás</h3>
      <p>Weboldalunk sütiket használ a működéséhez, statisztikai adatok gyűjtéséhez és releváns hirdetések megjelenítéséhez. Kérjük fogadd el az ajánlott beállításokat a portál használatához. A sütikről részletesebben a <a className="link" onClick={showCookieDisclaimer}>süti szabályzatra</a> kattintva olvashatsz.</p>
      <p className="action">
        <button onClick={acceptCookie}>Elfogadom az ajánlott beállításokat</button>
      </p>
      <div className="cookie-consent__links">
        <a className="btn-text" onClick={showCookieDetails}>Testreszabom</a>
        <a onClick={showCookieDisclaimer}>Süti szabályzat</a>
      </div>
    </div>
  )
}

export default CookieConsentAlert;
