import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import 'moment/locale/hu';
import Layout from '../../Layout/Layout';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import AnalyticsService from '../../../services/analytics';
import ResultItem from './ResultItem';
import Loader from '../../common/Loader';
import Standings from '../Standings/Standings';
import ResultsSquadNavigation from './ResultsSquadNavigation';
import ResultsTypeNavigation from './ResultsTypeNavigation';
import { resultsTypeState } from 'atoms';
import SortIcon from 'components/shared/SVG/SortIcon';
import SpinnerInner from 'components/shared/SpinnerInner/SpinnerInner';

import './results.scss';

function Results(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [fixtures, setFixtures] = useState([]);
  const [results, setResults] = useState([]);
  const [selectedSquadId, setSelectedSquadId] = useState(null);
  const [error, setError] = useState(false);
  const [standings, setStandings] = useState([]);
  const [standingsTitle, setStandingsTitle] = useState('');
  const { squad } = useParams();
  const resultType = useRecoilValue(resultsTypeState);
  const squadList = [
    {
      id: 1,
      ifa: 'nb2',
      name: 'elso-csapat',
      title: 'Első csapat'
    },
    {
      id: 20,
      ifa: 'nb3',
      name: 'masodik-csapat',
      title: 'Második csapat'
    },
    {
      id: 19,
      ifa: 'noi',
      name: 'noi-csapat',
      title: 'Női csapat'
    }
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    AnalyticsService.sendPageView(window.location.pathname);
    const selectedSquad = squadList.find((elem) => elem.name === squad);
    setSelectedSquadId(selectedSquad.id);

    fetch(`${API_BASE_URL}/fixtures/all?squad=${selectedSquad.id}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          const mappedResults = mapResults(json);
          setResults(mappedResults.filteredResultEventsByMonth);
          setFixtures(mappedResults.filteredFixtureEventsByMonth);
          setIsLoaded(true);
          setError(false);
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        },
      );

    fetch(`${API_BASE_URL}/ifa/standings?league=${selectedSquad.ifa}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          setStandingsTitle(`${json[0].csoport} ${json[0].evad}`);
          setStandings(json[0].items);
          setError(false);
        },
        (error) => { },
      );
  }, [squad]);

  const filterEmptyMonths = (eventsByMonth) => {
    const filtered = {};
    for (const key in eventsByMonth) {
      if (eventsByMonth[key].length > 0) {
        filtered[key] = eventsByMonth[key];
      }
    }
    return filtered;
  };

  const mapResults = (events) => {
    const { fixtureEventsByMonth, resultEventsByMonth } = events.reduce((accumulated, event) => {
      const date = new Date(event.event_date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const key = `${year}-${month}`;

      if (!accumulated.fixtureEventsByMonth[key]) {
        accumulated.fixtureEventsByMonth[key] = [];
      }

      if (!accumulated.resultEventsByMonth[key]) {
        accumulated.resultEventsByMonth[key] = [];
      }

      if (event.home_squad_goals === null || event.away_squad_goals === null) {
        accumulated.fixtureEventsByMonth[key].push(event);
      } else {
        accumulated.resultEventsByMonth[key].push(event);
      }

      return accumulated;
    }, {
      fixtureEventsByMonth: {},
      resultEventsByMonth: {},
    });

    const filteredFixtureEventsByMonth = filterEmptyMonths(fixtureEventsByMonth);
    const filteredResultEventsByMonth = filterEmptyMonths(resultEventsByMonth);

    return { filteredFixtureEventsByMonth, filteredResultEventsByMonth };
  }

  const handleSortClick = (type, reverse) => {
    const data = type === 'fixtures' ? fixtures : results;

    const sortedEntries = Object.entries(data).sort((a, b) => {
      const [yearA, monthA] = a[0].split('-').map(Number);
      const [yearB, monthB] = b[0].split('-').map(Number);

      if (yearA === yearB) {
        return monthA - monthB;
      }

      return yearA - yearB;
    });

    if (reverse) sortedEntries.reverse();

    const sortedObject = Object.fromEntries(sortedEntries);

    if (type === 'fixtures') {
      setFixtures(sortedObject);
    } else {
      setResults(sortedObject);
    }

  }

  if (error) {
    return <div>Nincs megjeleníthető eredmény!</div>;
  } if (!isLoaded) {
    return (
      <Layout type="slim" cssClassName="results-container">
        <div className="results-header-background">
          <div className="container">
            <h2 className="results-header">Mérkőzések</h2>
            <ResultsSquadNavigation squad={squad} />
          </div>
        </div>
        <section className="main-content">
          <div className="container relative spinner-container">
            <SpinnerInner />
          </div>
        </section>
      </Layout>
    );
  }
  return (
    <Layout type="slim" cssClassName="results-container">
      <div className="results-header-background">
        <div className="container">
          <h2 className="results-header">Mérkőzések</h2>
          <ResultsSquadNavigation squad={squad} />
        </div>
      </div>
      <section className="main-content">
        <div className="container relative">
          <ResultsTypeNavigation />
          {resultType === 'fixtures' && !!Object.entries(fixtures).length && (
            <div className="fixtures-container">
              <div className="sorting">
                <button className="btn btn-icon rotated" onClick={() => handleSortClick('fixtures', true)} title="Dátum szerint csökkenő">
                  <SortIcon />
                </button>
                <button className="btn btn-icon" onClick={() => handleSortClick('fixtures', false)} title="Dátum szerint növekvő">
                  <SortIcon />
                </button>
              </div>
              {Object.entries(fixtures).map(([key, events]) => {
                const [year, month] = key.split('-').map(Number);
                const formattedDate = moment(`${year}-${month}`, 'YYYY-M').format('YYYY. MMMM');

                return (
                  <div key={key}>
                    <div className="row">
                      <div className="col-12">
                        <h3 className="results-month">
                          {formattedDate}
                        </h3>
                      </div>
                    </div>
                    <div className="results-item-container">
                      <ResultItem items={events} squad={selectedSquadId} />
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          {resultType === 'fixtures' && !Object.entries(fixtures).length && (
            <div className="fixtures-container">
              <p className="no-data">Nincs következő mérkőzés a menetrendben.</p>
            </div>
          )}

          {resultType === 'results' && (
            <div className="fixtures-container">
              <div className="sorting">
                <button className="btn btn-icon rotated" onClick={() => handleSortClick('results', true)} title="Dátum szerint csökkenő">
                  <SortIcon />
                </button>
                <button className="btn btn-icon" onClick={() => handleSortClick('results', false)} title="Dátum szerint növekvő">
                  <SortIcon />
                </button>
              </div>
              {Object.entries(results).map(([key, events]) => {
                const [year, month] = key.split('-').map(Number);
                const formattedDate = moment(`${year}-${month}`, 'YYYY-M').format('YYYY. MMMM');

                return (
                  <div key={key}>
                    <div className="row">
                      <div className="col-12">
                        <h3 className="results-month">
                          {formattedDate}
                        </h3>
                      </div>
                    </div>
                    <div className="results-item-container">
                      <ResultItem items={events} />
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          {resultType === 'standings' && (
            <div className="standings-container">
              <Standings standingsData={standings} title={standingsTitle} />
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
}

export default Results;
