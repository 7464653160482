import React, { useEffect, useState } from 'react';
import './videorecommandation.scss';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import Loader from '../../common/Loader';
import VideoPreview from './VideoPreview';
import { v4 as uuidv4 } from 'uuid';

function VideoRecommandation(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [videoItems, setVideoItems] = useState([]);
  const [error, setError] = useState(false);
  const [activatedVideo, setActivatedVideo] = useState(0);

  useEffect(() => {
    fetch(`${API_BASE_URL}/video/list?id=honved-tv&amount=2`, {
      headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': API_KEY,
          'X-Request-ID': uuidv4()
        }
      })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then(
        (json) => {
          setVideoItems(json);
          setIsLoaded(true);
          setError(false);
        },
        (error) => {
          setVideoItems([]);
          setIsLoaded(true);
          setError(true);
        },
      );
  }, []);

  const activateVideo = (id) => {
    console.log(id);
    setActivatedVideo(id);
  };

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!isLoaded) {
    return (
      <div className="video-outer-container clearfix">
        <h2>Honvéd TV</h2>
        <div className="container">
          <div className="col-lg-6">
            <Loader />
          </div>

          <div className="col-lg-6">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="video-outer-container clearfix">
      <h2>Honvéd TV</h2>
      <div className="container">
        {videoItems.map((item) => (
          <div key={item.id}>
            <VideoPreview
              id={item.id}
              title={item.title}
              description={item.description}
              activateVideo={(e) => activateVideo(e)}
              activatedVideo={activatedVideo}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoRecommandation;
