import React, { Component } from 'react';
import { STATIC_SERVER_URL } from '../../config/config';
import '../../sass/components/common/loader.scss';

export default class Loader extends Component {
  render() {
    return (
      <div className="loader">
        <img src={`${STATIC_SERVER_URL}/web/images/draft/spacer.png`} alt="" />
      </div>
    );
  }
}
