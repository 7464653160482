import React from 'react';
import { Link } from 'react-router-dom';
import AsyncImage from '../../ui/AsyncImage/AsyncImage';

function TwoSideCard(props) {
  const backCardFileName = props.photo_name_card.replace(/_[0-9]{2}(.png)/, '_02.png');

  return (
    <div className="player-card">
      <Link to={`/jatekos-adatlap/${props.id}?squad=${props.squad}`}>
        <div className="player-card-inner">
          <figure className="player-card__front">
            <AsyncImage
              src={`${props.photo_name_card}`}
              alt={props.name}
              spacerImage="spacer-player.png"
            />
          </figure>
          <figure className="player-card__back">
            <AsyncImage
              src={`${backCardFileName}`}
              alt={props.name}
              spacerImage="spacer-player.png"
            />
          </figure>
        </div>
      </Link>
    </div>
  );
}

export default TwoSideCard;
