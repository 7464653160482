import React, { Component } from 'react';
import _ from 'lodash';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import { v4 as uuidv4 } from 'uuid';


export default class Roster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      itemsStartingEleven: [],
      itemsBench: [],
      isLoaded: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.getItems(this.props.coverageId);
    this.refreshItems(this.props.coverageId);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getItems(id) {
    fetch(`${API_BASE_URL}/coverageroster/${id}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            isLoaded: true,
            itemsStartingEleven: _.filter(json, { squad_id: parseInt(this.props.squad), type: 1 }),
            itemsBench: _.filter(json, { squad_id: parseInt(this.props.squad), type: 2 }),
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  refreshItems(id) {
    this.timer = setInterval(() => {
      console.log('roster refreshed');
      this.getItems(id);
    }, 60000);
  }

  render() {
    const { error, itemsStartingEleven, itemsBench } = this.state;

    return (
      <div>
        <table className="table">
          <tbody>
            {itemsStartingEleven.map((item) => (
              <tr key={item.id}>
                <td>{item.player_jersey}</td>
                <td>{item.player_name}</td>
                <td />
              </tr>
            ))}
          </tbody>
        </table>
        {!!itemsBench.length && (
          <>
            <h5>Kispad</h5>
            <table className="table">
              <tbody>
                {itemsBench.map((item) => (
                  <tr key={item.id}>
                    <td>{item.player_jersey}</td>
                    <td>{item.player_name}</td>
                    <td />
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  }
}
