import React, { Component } from 'react';

import Editorial from '../../ui/Editorial';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import Loader from '../../common/Loader';
import { v4 as uuidv4 } from 'uuid';

import '../../ui/NewsList/newslist.scss';

export default class RecommendedNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    fetch(`${API_BASE_URL}/news/latest?amount=4`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            isLoaded: true,
            items: json,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  render() {
    const { error, isLoaded, items } = this.state;

    if (error) {
      return <div>Hiba történt!</div>;
    } if (!isLoaded) {
      return (
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6 editorial-loader-container">
              <Loader />
            </div>
            <div className="col-sm-12 col-lg-6 editorial-loader-container">
              <Loader />
            </div>
            <div className="col-sm-12 col-lg-6 editorial-loader-container">
              <Loader />
            </div>
            <div className="col-sm-12 col-lg-6 editorial-loader-container">
              <Loader />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="row">
          {items.map((item) => (
            <Editorial
              key={item.id}
              id={item.id}
              category={item.category}
              title={item.title}
              lead={item.lead}
              slug={item.slug}
              photo_name={item.photo_name}
              upload_date={item.upload_date}
              publish_date={item.publish_date}
            />
          ))}
        </div>
      </div>
    );
  }
}
