import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../Layout/Layout';
import Breadcrumb from '../../common/Breadcrumb';
import AnalyticsService from '../../../services/analytics';
import { useState } from 'react';
import { API_BASE_URL } from '../../../config/config';

function PlayerDetails(props) {
  const [items, setItems] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    AnalyticsService.sendPageView(window.location.pathname);
    getItems();
  }, []);

  const getItems = () => {
    fetch(`${API_BASE_URL}/roster/player?id=${id}`)
      .then((res) => res.json())
      .then(
        (json) => {
          if (json.length) {
            const names = json[0].name.split(' ');
            json[0].firstName = names[0];
            json[0].lastName = names[1];
            setItems(json);
          } else {
            navigate('/404');
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <Layout type="slim" cssClassName="article-container">
      <Breadcrumb />

      <section className="main-content">
        <div className="container">
          {items.map((item) => (
            <div className="row player-card player-details" key={item.id}>
              <div className="col-sm-12 col-lg-6">
                <figure>
                  <img className="img-fluid" src={`${item.photo_name_card}`} alt={item.name} />
                </figure>
              </div>
              <div className="col-sm-12 col-lg-6">
                <div>
                  <h3>{item.squad_number}</h3>
                  <h2>
                    <span>{item.firstName}</span>
                    <span>{item.lastName}</span>
                  </h2>
                </div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Állampolgárság:</td>
                      <td>{item.nationality_text}</td>
                    </tr>
                    <tr>
                      <td>Születésnap:</td>
                      <td>{item.date_of_birth}</td>
                    </tr>
                    <tr>
                      <td>Születés hely:</td>
                      <td>{item.place_of_birth}</td>
                    </tr>
                    <tr>
                      <td>Magasság:</td>
                      <td>{item.height} cm</td>
                    </tr>
                    <tr>
                      <td>Testsúly:</td>
                      <td>{item.weight} kg</td>
                    </tr>
                    <tr>
                      <td>Poszt:</td>
                      <td>{item.position_name}</td>
                    </tr>
                    <tr>
                      <td>Érkezett:</td>
                      <td>{item.signed}</td>
                    </tr>
                    <tr>
                      <td>Előző klubok:</td>
                      <td>{item.previous_clubs}</td>
                    </tr>
                    <tr>
                      <td>Első meccse Kispesten:</td>
                      <td>{item.first_appearance}</td>
                    </tr>
                    <tr>
                      <td>Mezszám:</td>
                      <td>{item.squad_number}</td>
                    </tr>
                    <tr>
                      <td>Válogatottság:</td>
                      <td>{item.caps}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
}

export default PlayerDetails;
