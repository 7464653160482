import React, { useEffect, useState } from 'react';

import { API_BASE_URL, API_KEY } from '../../../config/config';
import Loader from '../Loader';
import BannerContent from './BannerContent';
import { v4 as uuidv4 } from 'uuid';

import './banner_container.scss';

function BannerContainer(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/banners/show?id=${props.type}`, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': API_KEY,
          'X-Request-ID': uuidv4()
        }
      })
      .then((res) => res.json())
      .then(
        (json) => {
          setBanner(json[0]);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
        },
      );
  }, [props.type]);

  if (isLoading) {
    return (
      <div className={`banner-container ${props.cssClassName}`}>
        <div className="show-on-mobile">
          <Loader />
        </div>
        <div className="not-show-on-mobile">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className={`banner-container ${props.cssClassName}`}>
      <div className="show-on-mobile">
        <BannerContent
          url={banner.url}
          target={banner.target}
          fileName={banner.file_name_mobile}
          altText={banner.alt_text}
        />
      </div>
      <div className="not-show-on-mobile">
        <BannerContent
          url={banner.url}
          target={banner.target}
          fileName={banner.file_name}
          altText={banner.alt_text}
        />
      </div>
    </div>
  );
}

export default BannerContainer;
