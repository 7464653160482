import React, { Component } from 'react';
import moment from 'moment/moment';
import { DATE_LABELS } from '../../config/config';

export default class DateFormater extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
    };

    this.state.date = this.reformatDateTime(this.props.dateTime);
  }

  reformatDateTime(rawDate) {
    let formattedDate = '';
    const articleDate = new Date(rawDate);
    const diffInSeconds = Math.round((new Date() - articleDate) / 1000);

    if (diffInSeconds < 300) {
      formattedDate = DATE_LABELS.rightNow;
    } else if (diffInSeconds < 3600) {
      formattedDate = `${Math.round(diffInSeconds / 60)} ${DATE_LABELS.minutesBefore}`;
    } else if (diffInSeconds < 86400) {
      formattedDate = `${Math.round(diffInSeconds / 3600)} ${DATE_LABELS.hoursBefore}`;
    } else if (diffInSeconds < 259200) {
      formattedDate = `${Math.floor(diffInSeconds / 86400)} ${DATE_LABELS.daysBefore}`;
    } else {
      /* let month = ((articleDate.getMonth() + 1) < 10) ? '0' + (articleDate.getMonth() + 1) : (articleDate.getMonth() + 1);
            formattedDate = articleDate.getFullYear() + '-' + month + '-' + articleDate.getDate() + ' ' + articleDate.getHours() + ':' + articleDate.getMinutes(); */
      formattedDate = moment(rawDate).format('YYYY.MM.DD HH:mm');
    }
    return formattedDate;
  }

  render() {
    const { date } = this.state;

    return (
      <span>
        {date}
      </span>
    );
  }
}
