import { Link, useLocation } from "react-router-dom";
import CartIcon from "../SVG/CartIcon";
import HomeIcon from "../SVG/HomeIcon";
import JerseyIcon from "../SVG/JerseyIcon";
import VideoIcon from "../SVG/VideoIcon";

import classes from "./bottomNav.module.scss";

function BottomNav() {
  const location = useLocation();

  return (
    <nav className={classes.bottomNavigation}>
      <Link to={'/'} className={(location.pathname === '/') ? `${classes.item} ${classes.active}` : `${classes.item}`}>
        <HomeIcon />
        <p>Hírek</p>
      </Link>
      <Link to='/merkozesek/elso-csapat' className={(location.pathname.includes('merkozesek') === true) ? `${classes.item} ${classes.active}` : `${classes.item}`}>
        <JerseyIcon />
        <p>Mérkőzések</p>
      </Link>
      <Link to={'/media/videok'} className={(location.pathname.includes('videok') === true) ? `${classes.item} ${classes.active}` : `${classes.item}`}>
        <VideoIcon />
        <p>Videók</p>
      </Link>
      <a href="https://shop.honvedfc.hu" className={classes.item} target="_blank">
        <CartIcon />
        <p>Shop</p>
      </a>
    </nav>
  )
}

export default BottomNav;
