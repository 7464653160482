import React, { Component } from 'react';
import { STATIC_SERVER_URL } from '../../config/config';
import SearchField from './SearchField';

export default class NavigationIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFieldIsVisible: false,
      loginModalIsVisible: true,
    };
    this.showSearchField = this.showSearchField.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.showLoginModal = this.showLoginModal.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  showSearchField() {
    this.setState({ searchFieldIsVisible: true });
  }

  showLoginModal() {
    this.setState({ loginModalIsVisible: true });
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.setState({ searchFieldIsVisible: false });
    }
  }

  render() {
    return (
      <div>
        <ul className="navigation-icons">
          <li><img onClick={this.showSearchField} src={`${STATIC_SERVER_URL}/web/images/draft/icon_lens.png`} alt="Keresés" /></li>
          {/* <li><span>EN</span></li> */}
        </ul>
        <SearchField active={this.state.searchFieldIsVisible} />
      </div>
    );
  }
}
