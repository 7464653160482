import React from 'react';
import './standings.scss';

function Standings({ standingsData, title }) {
  return (
    <div className="standings-content">
      <h2 className="standings-header">{title}</h2>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Csapat</th>
            <th className="xxl">J</th>
            <th className="xxl">GY</th>
            <th className="xxl">D</th>
            <th className="xxl">V</th>
            <th className="xxl">Lőtt</th>
            <th className="xxl">Kapott</th>
            <th className="xxl">Gólkülönbség</th>
            <th>Pont</th>
          </tr>
        </thead>
        <tbody>
          {standingsData.map((item, index) => (
            <tr className={((item.csapat === 'Budapest Honvéd FC' || item.csapat === 'BUDAPEST HONVÉD-MFA II.') ? 'highlight' : '')} key={index}>
              <td>{item.hely}</td>
              <td>
                <div className="flex">
                  <img src={item.crest} alt={item.csapat} />
                  <span>{item.csapat}</span>
                </div>
              </td>
              <td className="xxl">{item.m}</td>
              <td className="xxl">{item.gy}</td>
              <td className="xxl">{item.d}</td>
              <td className="xxl">{item.v}</td>
              <td className="xxl">{item.lg}</td>
              <td className="xxl">{item.kg}</td>
              <td className="xxl">{item.gk}</td>
              <td>{item.p}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Standings;
