import React, { useEffect } from 'react';

import Layout from '../../Layout/Layout';
import Breadcrumb from '../../common/Breadcrumb';
import PlayListDetails from './PlayListDetails';
import AnalyticsService from '../../../services/analytics';

import './videos.scss';

function Videos() {
  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Layout type="slim" cssClassName="article-container">
      <Breadcrumb />

      <section className="main-content playlist-container">
        <div className="container">
          <h3 className="video-header">Honvéd TV</h3>
          <PlayListDetails id="honved-tv" amount={4} />
          <h3 className="video-header">Mérkőzés összefoglalók</h3>
          <PlayListDetails id="highlights" amount={4} />
          <h3 className="video-header">Mérkőzés beszámolók</h3>
          <PlayListDetails id="matches" amount={4} />
        </div>
      </section>
    </Layout>
  );
}

export default Videos;
