import React, { Component } from 'react';
import { STATIC_SERVER_URL } from '../../config/config';

export default class NavigationSocial extends Component {
  render() {
    return (
      <div className="social-sites-container">
        <ul className="social-sites">
          <li>
            <a
              href="https://www.youtube.com/user/budapesthonvedfc"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={`${STATIC_SERVER_URL}/web/images/draft/youtube.png`}
                alt="Youtube csatorna"
              />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/honvedfc/" target="_blank" rel="noreferrer">
              <img src={`${STATIC_SERVER_URL}/web/images/draft/facebook.png`} alt="Facebook" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/bphonvedfcofficial/?hl=hu"
              target="_blank"
              rel="noreferrer"
            >
              <img src={`${STATIC_SERVER_URL}/web/images/draft/instagram.png`} alt="Instagram" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/2962530" target="_blank" rel="noreferrer">
              <img src={`${STATIC_SERVER_URL}/web/images/draft/linkedin.png`} alt="LinkedIn" />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
