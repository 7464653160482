import React, { Component } from 'react';
import _ from 'lodash';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import { v4 as uuidv4 } from 'uuid';


export default class MatchScorers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      isLoaded: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    this.getItems(this.props.coverage);
    this.refreshItems(this.props.coverage);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getItems(coverageId) {
    fetch(`${API_BASE_URL}/coveragescorers/${coverageId}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            isLoaded: true,
            items: _.filter(json, { squad_id: this.props.squad }),
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  refreshItems(id) {
    this.timer = setInterval(() => {
      try {
        this.getItems(id);
      } catch(err) {
        console.log(err);
      }
    }, 120000);
  }

  render() {
    const { error, isLoaded, items } = this.state;

    if (error) {
      return <div>Hiba történt!</div>;
    } if (!isLoaded) {
      return <div>Betöltés</div>;
    }
    return (
      <ul className={this.props.textAlign}>
        {items.map((item) => (
          <li key={item.id}>
            {item.minutes}
            &rsquo;&nbsp;
            {item.name}
          </li>
        ))}
      </ul>
    );
  }
}
