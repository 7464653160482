import React, { useState } from 'react'
import ReactSwitch from 'react-switch';
import Cookie from '../svg/Cookie';

function CookieConsentDetails(props) {
  const [checkedLevel1, setLevel1] = useState(true);
  const [checkedLevel2, setLevel2] = useState(false);
  const [checkedLevel3, setLevel3] = useState(false);

  const handleLevel1Change = (e) => {
    setLevel1(e);
  }

  const handleLevel2Change = (e) => {
    setLevel2(e);
  }

  const handleLevel3Change = (e) => {
    setLevel3(e);
  }

  const changeConsentSettings = (e) => {
    e.preventDefault();
    props.isAcceptCookies((checkedLevel3) ? 3 : (checkedLevel2) ? 2 : 1);
  };

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    props.isShowDetails(false);
  };

  return (
    <div className='cookie-consent__details'>
      <div className="cookie-consent__header">
        <Cookie cssClassName="text-left no-bottom-margin" />
        <h3>Milyen sütik kezeléséhez járulsz hozzá?</h3>
      </div>
      <form>
        <div>
          <h4>Alap működést biztosító sütik</h4>
          <div className="cookie-consent__levels">
            <p>Ezek a sütik elengedhetetlenek ahhoz, hogy böngéssz a weboldalon és használhasd annak funkcióit. Emiatt ezeket a sütiket kötelező elfogadni.</p>
            <ReactSwitch
              checked={checkedLevel1}
              onChange={handleLevel1Change}
              onColor="#7BFA88"
              onHandleColor="#ffffff"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              height={20}
              width={48}
              className="react-switch"
              id="consent-level-1"
              disabled
            />
          </div>
        </div>
        <div>
          <h4>Statisztikai célú sütik</h4>
          <div className="cookie-consent__levels">
            <p>Ezek a sütik segítenek megérteni, hogyan viselkednek a látogatók a weboldalon, információt nyújtanak a látogatók számáról, mennyi időt töltenek a webhelyen, honnan érkeztek a weboldalra, és így tovább.</p>
            <ReactSwitch
              checked={checkedLevel2}
              onChange={handleLevel2Change}
              onColor="#7BFA88"
              onHandleColor="#ffffff"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              height={20}
              width={48}
              className="react-switch"
              id="consent-level-2"
            />
          </div>
        </div>
        <div>
          <h4>Célzó- és hirdetési sütik</h4>
          <div className="cookie-consent__levels">
            <p>Ezeket a sütiket arra használjuk, hogy információkat jutassunk el hozzád és mérjük hirdetéseink hatékonyságát. Az adataidat felhasználva olyan hirdetéseket, akciókat és információkat mutatunk, akár a partnereinkkel együtt, amelyek érdekelhetnek téged.</p>
            <ReactSwitch
              checked={checkedLevel3}
              onChange={handleLevel3Change}
              onColor="#7BFA88"
              onHandleColor="#ffffff"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              height={20}
              width={48}
              className="react-switch"
              id="consent-level-3"
            />
          </div>
        </div>
        <div className="consent-accept-container">
          <button onClick={changeConsentSettings}>Beállítások mentése</button>
          <button className="btn-outline" onClick={handleBackButtonClick}>Vissza</button>
        </div>
      </form>
    </div>
  )
}

export default CookieConsentDetails;
