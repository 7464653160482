import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export default class InnerNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((state) => ({
      isToggleOn: !state.isToggleOn,
    }));
  }

  render() {
    return (
      <nav className={`clearfix ${this.state.isToggleOn ? 'show' : 'hidden'}`}>
        <ul>
          <li><a href="#">kommentárok</a></li>
          <li><a href="#">értékelések</a></li>
          <li><a href="#">felállások</a></li>
          <li><a href="#">statisztikák</a></li>
          <li><a href="#">meccsfotók</a></li>
        </ul>
        <a className="accordion" onClick={this.handleClick} />
      </nav>
    );
  }
}
