import React, { Component } from 'react';

export default class Standings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    fetch('http://api.local/results/fetch/1')
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            isLoaded: true,
            items: json,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  render() {
    const { error, isLoaded, items } = this.state;
    const crestSource = '/images/crests/';

    if (error) {
      return <div>Hiba történt!</div>;
    } if (!isLoaded) {
      return <div>Betöltés</div>;
    }
    return (
      <div>
        <h4>Tabella</h4>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Csapat</th>
              <th className="xxl">GY</th>
              <th>Pont</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr className={(item.squad_id == 1 ? 'highlight' : '')} key={item.squad_id}>
                <td>{index + 1}</td>
                <td data-shortname={item.shortname} data-name={item.shortname} />
                <td className="xxl">{item.matches_won}</td>
                <td>{item.points}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <button className="btn btn-outline-info">teljes tabella</button>
      </div>
    );
  }
}

/* if (document.querySelector('.scoreboard')) {
    ReactDOM.render(<Scoreboard/>, document.querySelector('.scoreboard'));
} */
