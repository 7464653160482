import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL, API_KEY, STATIC_SERVER_URL } from '../../../config/config';

import AnalyticsService from '../../../services/analytics';
import Loader from '../../common/Loader';
import { v4 as uuidv4 } from 'uuid';

import './staff.scss';

function Staff({ squadId, title }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [staff, setStaff] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);

    fetch(`${API_BASE_URL}/staff/view?id=${getSquadId(squadId)}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Something went wrong');
      })
      .then(
        (json) => {
          setStaff(json);
          setIsLoaded(true);
          setError(false);
        },
        (error) => {
          setStaff([]);
          setIsLoaded(true);
          setError(true);
        },
      );
  }, [squadId]);

  const getSquadId = (id) => {
    return id === 'elso-csapat' ? 1 : id === 'masodik-csapat' ? 20 : 19;
  };

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!isLoaded) {
    return (
      <div className="container staff-container">
        <div className="row">
          <div className="col-12">
            <h3 className="text-center">{title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
          <div className="col-lg-3 featured-loader-container">
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container staff-container">
      <div className="row">
        <div className="col-12">
          <h3 className="text-center">{title}</h3>
        </div>
      </div>
      <div className="row">
        {staff.map((item) => (
          <div className="col-sm-12 col-lg-3" key={item.id}>
            <div className="staff-card">
              <figure>
                <img src={`${STATIC_SERVER_URL}/web/images/staff/${item.photo}`} alt="" />
              </figure>
              <div className="details">
                <h4>{item.name}</h4>
                <p>{item.title_name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Staff;
