import React, { Component } from 'react';
import Hamburger from '../../common/Hamburger';
import Navigation from '../../common/Navigation';
import Breadcrumb from '../../common/Breadcrumb';
import '../register/register.scss';
import { AUTH_BASE_URL } from '../../../config/config';
import { Link } from 'react-router-dom';

export default class ValidateCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isKeyValid: true,
      sign: this.props.match.params.id,
      validationCode: null,
      isFinalized: false,
    };
    this.validateKey = this.validateKey.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateKey();
  }

  validateKey() {
    fetch(`${AUTH_BASE_URL}/validate-key/${this.props.match.params.id}`)
      .then((res) => res.json())
      .then(
        (json) => {
          console.log(json);
          this.setState({
            isKeyValid: json,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  handleChange(event) {
    const isCheckbox = event.target.type === 'checkbox';

    this.setState({
      [event.target.id]: isCheckbox
        ? event.target.checked
        : event.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log(event);

    fetch(`${AUTH_BASE_URL}/finalize-registration`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.state),
    })
      .then((res) => res.json())
      .then(
        (json) => {
          console.log(json);
          this.setState({
            isFinalized: json,
          });
          /* this.props.history.push('/validate-code/' + json['sign']); */
        },
        (error) => {
          console.log(error);
        },
      );
  }

  render() {
    const { isKeyValid, isFinalized } = this.state;

    return (
      <div className="fixtures-container">
        <header>
          <Hamburger />
          <Navigation />
        </header>

        <Breadcrumb />

        {isKeyValid && !isFinalized && (
        <section className="main-content register-background">
          <div className="container club-register-container">
            <h2 className="news-list-header">Már csak egy lépés</h2>
            <p className="text-center">A regisztrációd érvényesítéséhez add meg az e-mail-ben kapott hat számjegyű kódot.</p>
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div className="form-group col-md-6 offset-3 text-center">
                  <input
                    type="text"
                    className="form-control"
                    id="validationCode"
                    disabled={isFinalized}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary">Érvényesítem</button>
            </form>
          </div>
        </section>
        )}
        {isKeyValid && isFinalized && (
        <section className="main-content register-background">
          <div className="container club-register-container">
            <h2 className="news-list-header">Végeztünk!</h2>
            <p className="text-center">A regisztrációdat aktiváltad, innentől minden extra funkciót és tartalmat elérsz.</p>
            <Link className="btn btn-primary" to="/legfrissebb">Tovább a hírekhez</Link>
          </div>
        </section>
        )}
        {!isKeyValid && (
        <section className="main-content">
          <div className="container club-register-container">
            <h2 className="news-list-header">Hiba</h2>
            <p>Nem aktiválható a felhasználói fiók, vagy a kód érvénytelen!</p>
          </div>
        </section>
        )}
      </div>
    );
  }
}
