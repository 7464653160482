import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../Layout/Layout';
import Breadcrumb from '../../common/Breadcrumb';
import AnalyticsService from '../../../services/analytics';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import Editorial from '../../ui/Editorial';
import Featured from '../../ui/Featured';
import Loader from '../../common/Loader';
import { v4 as uuidv4 } from 'uuid';

function NewsList(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [itemsEditorial, setItemsEditorial] = useState([]);
  const [itemsFeatured, setItemsFeatured] = useState([]);
  const [error, setError] = useState(false);
  const [navigationName, setNavigationName] = useState('');
  const { id } = useParams();

  useEffect(() => {
    AnalyticsService.sendPageView(window.location.pathname);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    fetch(`${API_BASE_URL}/news/list/${id}?amount=14`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          setItemsEditorial(json.slice(0, 2));
          setItemsFeatured(json.slice(2, 14));
          setIsLoaded(true);
          setError(false);
          getNavigationName();
        },
        (error) => {
          setIsLoaded(true);
          setError(true);
        },
      );
  }, [id]);

  const getNavigationName = () => {
    fetch(`${API_BASE_URL}/navigation/show?path=${btoa(document.location.pathname)}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          setNavigationName(json[0].label_hu);
        },
        (error) => {
          setError(true);
        },
      );
  };

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!isLoaded) {
    return (
      <Layout type="slim" cssClassName="article-container">
        <Breadcrumb />
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6 editorial-loader-container">
              <Loader />
            </div>
            <div className="col-sm-12 col-lg-6 editorial-loader-container">
              <Loader />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
            <div className="col-lg-3 featured-loader-container">
              <Loader />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
  return (
    <Layout type="slim" cssClassName="article-container">
      <Breadcrumb />

      <section className="main-content">
        <div className="container">
          <h2 className="news-list-header">{navigationName}</h2>
          <div className="row">
            {itemsEditorial.map((item) => (
              <Editorial
                key={item.id}
                id={item.id}
                category={item.category}
                title={item.title}
                lead={item.lead}
                slug={item.slug}
                photo_name={item.photo_name}
                upload_date={item.upload_date}
                publish_date={item.publish_date}
                coverage_id={item.coverage_id}
              />
            ))}
          </div>
          <div className="row">
            {itemsFeatured.map((item) => (
              <Featured
                key={item.id}
                id={item.id}
                category={item.category}
                title={item.title}
                lead={item.lead}
                slug={item.slug}
                photo_name={item.photo_name}
                upload_date={item.upload_date}
                publish_date={item.publish_date}
                coverage_id={item.coverage_id}
              />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default NewsList;
