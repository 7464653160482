import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import moment from 'moment';
import { API_BASE_URL, API_KEY, NEWS_CATEGORIES, STATIC_SERVER_URL } from '../../config/config';
import DateFormater from '../common/DateFormater';
import * as ImageService from '../../services/newsImage';
import { appFrontPageNews } from '../../atoms';
import { v4 as uuidv4 } from 'uuid';

import '../../sass/components/featured-news.scss';

function FeaturedNews(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [newsItem, setNewsItem] = useState([]);
  const [imageItem, setImageItem] = useState([]);
  const [imageMax, setImageMax] = useState([]);
  const [error, setError] = useState(false);
  const newsItems = useRecoilValue(appFrontPageNews);

  useEffect(() => {
    getHeaderImage().then((imageData) => {
      setImageItem(imageData);
      setIsLoaded(true);
      setError(false);
      setImageMax(imageData.reduce((prev, current) => (prev.max_resolution > current.max_resolution) ? prev : current));
    });
  }, []);

  useEffect(() => {
    setNewsItem(newsItems.filter((item) => {
      return item.is_editorial === 11;
    }).slice(0,1));
  }, [newsItems]);

  const getHeaderImage = () => {
    return new Promise((resolve, reject) => {
      fetch(API_BASE_URL + '/header-image', {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'X-API-KEY': API_KEY,
          'X-Request-ID': uuidv4()
        }
      })
      .then(res => res.json())
      .then(
        (json) => {
          resolve(json);
        },
        (error) => {
          reject(error);
        }
      )
    })
  }

  if (error) {
    return <div>Hiba történt!</div>;
  } if (!isLoaded) {
    return (
      <div className="featured-news-loader" />
    );
  } if (!newsItem.length && imageItem.length) {
    return (
      <div>
        <figure id="wallpaper" className="header-image">
          <picture>
            {imageItem.map(item => (
            <source srcSet={`${STATIC_SERVER_URL}/web/images/header/${item.file_name}`} media={`(max-width: ${item.max_resolution - 1}px)`} key={item.id} />
            ))}
            <img src={`${STATIC_SERVER_URL}/web/images/header/${imageMax.file_name}`} alt="Fejléc" />
          </picture>
        </figure>
      </div>
    );
  }
  return (
    <div>
      {newsItem.map((item) => (
        <Link to={(item.coverage_id) ? `/online-kozvetites/${item.coverage_id}` : `/hirek/${item.slug}/${moment(item.upload_date).format('YYYY-MM-DD')}`} key={item.id}>
          <div>
            <h2>{item.title}</h2>
            <p>
              <DateFormater dateTime={item.publish_date} />
              {' '}
              //
              {' '}
              {NEWS_CATEGORIES[item.category]}
            </p>
            <figure id="wallpaper">
              <img src={`${STATIC_SERVER_URL}/web/images/news/${item.photo_name}`} alt={item.title} />
            </figure>
            <div className="shadow" />
          </div>
        </Link>
      ))}
    </div>
  );
}

export default FeaturedNews;
