import React, { Component } from 'react';
import Roster from './Roster';
import { API_BASE_URL, API_KEY } from '../../../config/config';
import { v4 as uuidv4 } from 'uuid';

export default class Lineups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      items: [],
      isLoaded: false,
      tabStates: {},
    };
    this.timer = null;
  }

  componentDidMount() {
    this.getItems(this.props.id);
    this.refreshItems(this.props.id);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  refreshItems(id) {
    this.timer = setInterval(() => {
      console.log('base data refresh');
      try {
        this.getItems(id);
      } catch(err) {
        console.log(err);
      }
    }, 120000);
  }

  getItems(coverageId) {
    fetch(`${API_BASE_URL}/coveragebasedata/${coverageId}`, {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'X-API-KEY': API_KEY,
        'X-Request-ID': uuidv4()
      }
    })
      .then((res) => res.json())
      .then(
        (json) => {
          this.setState({
            isLoaded: true,
            items: json,
            tabStates: {
              [json[0].home_squad_id]: (json[0].home_squad_id === 1) ? 1 : 0,
              [json[0].away_squad_id]: (json[0].away_squad_id === 1) ? 1 : 0,
            },
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        },
      );
  }

  toggleClass(id) {
    this.setState({
      tabStates: {
        [id]: true,
      },
    });
  }

  render() {
    const {
      error, isLoaded, items, tabStates,
    } = this.state;

    if (error) {
      return <div>Hiba történt!</div>;
    } if (!isLoaded) {
      return <div>Betöltés</div>;
    }
    return (
      <div>
        {items.map((item) => (
          <div key={item.id}>
            <h4>Kezdőcsapatok</h4>
            <div className="tab-head-container">
              <a
                id={`squad_${item.home_squad_id}`}
                className={(tabStates[item.home_squad_id] == 1) ? 'active' : ''}
                onClick={() => this.toggleClass(item.home_squad_id)}
                data-shortname={item.home_squad_short_name}
                data-name={item.home_squad_name}
              />
              <a
                id={`squad_${item.away_squad_id}`}
                className={(tabStates[item.away_squad_id] == 1) ? 'active' : ''}
                onClick={() => this.toggleClass(item.away_squad_id)}
                data-shortname={item.away_squad_short_name}
                data-name={item.away_squad_name}
              />
            </div>
            <div className={`tab-content ${(tabStates[item.home_squad_id] == 1) ? 'active' : ''}`}>
              <Roster squad={item.home_squad_id} coverageId={this.props.id} />
            </div>
            <div className={`tab-content ${(tabStates[item.away_squad_id] == 1) ? 'active' : ''}`}>
              <Roster squad={item.away_squad_id} coverageId={this.props.id} />
            </div>
          </div>
        ))}
      </div>
    );
  }
}
